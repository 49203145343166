export const FEATURE_FLAGS = [
  {
    name: "new-tender-2",
    local: "control", // control, variant1, variant2
  },
  {
    name: "automatic-calculation-listpage",
    local: "control", // control, variant1
  },
  {
    name: "automatic-calculation",
    local: "control", // control, variant1
  },
  {
    name: "phone-extension-display",
    local: "control", // control, variant1
  },
  {
    name: "project-examples-round-2",
    local: "control", // control, test
  },
  {
    name: "favorite-mate",
    local: "control", // control, variant1
  },
  {
    name: "person-card-price",
    local: "control", // control, variant1
  },
  {
    name: "hide-tenderbox",
    local: "control", // control, variant1
  },
  //  {
  //    name: 'quick-offer-placholder-badogos',
  //    local: 'test', // control, test
  //  },
  {
    name: "city-by-ip",
    local: "control", // control, test
  },
  {
    name: "tender-merge-step-6-7",
    local: "control", // control, test
  },
  {
    name: "profile-quick-tender",
    local: "control", // control, variant1
  },
  {
    name: "card-person-vertical",
    local: "control", // control, variant1
  },
  {
    name: "new-profile-2",
    local: "control", // control, variant1, variant2
  },
  {
    name: "new-profile-body",
    local: "control", // control, variant1, variant2
  },
  {
    name: "request-tender-person-card",
    local: "control", // control, test
  },
  {
    name: "request-tender-generic-card",
    local: "control", // control, test
  },
  {
    name: "old-header-search",
    local: "control", // control, test
  },
  {
    name: "profile-page-mobile",
    local: "control", // control, test
  },
  {
    name: "prof-city-page-header-qa-style",
    local: "control", // control, test
  },
  {
    name: "prices-page-phone-box",
    local: "control", // control, test
  },
  {
    name: "prices-page-content-block-tender",
    local: "control", // control, test
  },
  {
    name: "mobile-help-text",
    local: "control", // control, test
  },
  {
    name: "prices-top-6-professional",
    local: "control", // control, test
  },
  {
    name: "cards-request-now-text",
    local: "control", // control, test
  },
  {
    name: "cookie-banner",
    local: "control", // control, test
  },
  {
    name: "trust-element-feri",
    local: "control", // control, test
  },
  {
    name: "show-tutorial-card-in-settings",
    local: "control", // control, test
  },
  {
    name: "prices-page-consulting-block",
    local: "control", // control, test
  },
  {
    name: "prices-page-consulting-block-top",
    local: "control", // control, test
  },
  {
    name: "prices-page-city-selector-higher",
    local: "control", // control, test
  },
  {
    name: "profile-answers",
    local: "control", // control, test
  },
  {
    name: "request-tender-with-profession-task",
    local: "control", // control, test
  },
  {
    name: "new-question-page",
    local: true,
  },
  {
    name: "new-ordered-mates-query",
    local: "control", // control, test
  },
  {
    name: "new-price-page-query",
    local: "control", // control, test
  },
];
