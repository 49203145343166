export const state = () => {
  return {
    counties: [],
    professionGroups: [],
    popularProfessions: [],
    popularProfessionTasks: [],
    cities: [],
    professions: [],
  };
};

export const getters = {
  cities: (state) => state.cities,
  popularProfessions: (state) => state.popularProfessions,
  popularProfessionTasks: (state) => state.popularProfessionTasks,
  counties: (state) => state.counties,
  professionGroups: (state) => state.professionGroups,
  professions: (state) => state.professions,
  professionsById: (state) => {
    if (state.professions?.length === 0) {
      return {};
    }
    return state.professions.reduce((acc, prof) => ({
      ...acc,
      [prof.id]: prof,
    }));
  },
};

export const mutations = {
  setData(
    state,
    {
      counties,
      professionGroups,
      popularProfessions,
      popularProfessionTasks,
      cities,
    }
  ) {
    state.counties = counties;
    state.professionGroups = professionGroups;
    state.popularProfessions = popularProfessions;
    state.popularProfessionTasks = popularProfessionTasks;
    state.cities = cities;
    state.professions = [];
    if (state.professionGroups) {
      for (const pg of state.professionGroups) {
        for (const prof of pg.professions) {
          prof.professionGroup = {
            id: pg.id,
            name: pg.name,
            seoName: pg.seoName,
          };
          state.professions.push(prof);
        }
      }
    }
    // sort professions by name
    state.professions.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  },
};

export const actions = {
  async loadBackendData({ commit }, context) {
    try {
      const INIT_QUERY = await import("./initQ.graphql");
      const callQuery = () => {
        return context.$query(INIT_QUERY);
      };
      let resp;
      if (process.server) {
        resp = await context.$cachedData(
          "backendData",
          60 * 60 * 24,
          callQuery
        );
      } else {
        resp = await callQuery();
      }
      commit("setData", resp);
    } catch (err) {
      console.error(err);
      context.$sentry.captureException(err, {
        tags: {
          graphql: "storeInit",
        },
      });
    }
  },
};
