import { capitalizeFirstLetter } from "~/helpers/jsutil";

/**
 * Replaces all keys of given mapObj with matching values of mapObj
 *
 * @param {string} str
 * @param {Object} mapObj  object with key value pairs to replace
 * @returns
 */
export function replaceAll(str, mapObj) {
  const re = new RegExp(Object.keys(mapObj).join("|"), "g");
  if (Array.isArray(str)) {
    str = str.map((x) =>
      typeof x === "string" ? x.replace(re, (matched) => mapObj[matched]) : x
    );
    return str;
  }

  if (!str || typeof str === "object") {
    return str;
  }

  return str.replace(re, (matched) => mapObj[matched]);
}

function setNameValuePairToMap(value, name, replaceMap) {
  if (typeof value === "string") {
    replaceMap[`<${name}>`] = value;
  } else if (typeof value === "object") {
    for (const key in value) {
      setNameValuePairToMap(
        value[key],
        name + capitalizeFirstLetter(key),
        replaceMap
      );
    }
  } else {
    throw new TypeError("Unsupported type");
  }
}

function buildReplaceMap(branding, baseName = "branding") {
  if (!branding) {
    return {};
  }
  const replaceMap = {};
  for (const key in branding) {
    if (key === "default") {
      continue;
    }
    setNameValuePairToMap(
      branding[key],
      baseName + capitalizeFirstLetter(key),
      replaceMap
    );
  }
  return replaceMap;
}

export default async function (ctx, _inject) {
  const BRAND = process.env.BRAND;

  const branding = await import(`~/branding/${BRAND}.json`);

  /**
   * Work around for postTranslation not working in nuxt.config.js for some reason only string is accepted, that then throws not a function eror
   *
   * @param {*} str
   * @param {*} key
   * @returns
   */
  ctx.i18n.postTranslation = function (str, _key) {
    return replaceAll(str, {
      "<supportEmail>": ctx.$helpers.support.email,
      "<supportPhone>": ctx.$helpers.support.phone,
      "<supportEmailLink>": ctx.$helpers.support.emailLink,
      "<supportPhoneLink>": ctx.$helpers.support.phoneLink,
      ...buildReplaceMap(branding),
    });
  };
}
