<template>
  <div class="hidden"></div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";
import { useDefaultHead } from "~/composables/defaultHead";

/**
 * This component sets the default head for the application, handles async loading of branding that's controlled by env variables
 *
 * #TODO remove nuxt3
 */
export default defineComponent({
  props: {
    titleSuffix: {
      type: String,
      required: false,
      default: "",
    },
    extraMetas: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      headStuff: {},
    };
  },
  head() {
    console.debug("Headsetter head method");
    return { ...this.headStuff };
  },
  async created() {
    const defaultHead = await useDefaultHead();
    this.headStuff = { ...defaultHead };
    if (this.titleSuffix) {
      this.headStuff.title = `${this.headStuff.title} - ${this.titleSuffix}`;
    }
  },
});
</script>
