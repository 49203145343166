import { v4 as uuid } from "uuid";
export const JOSZAKI_ALERT_EVENT = "JOSZAKI_ALERT";
export const JOSZAKI_CONFIRM_EVENT = "JOSZAKI_CONFIRM";
export const JOSZAKI_SEARCH_SET_CITY = "JOSZAKI_SEARCH_SET_CITY";
export const JOSZAKI_TOAST_EVENT = "JOSZAKI_TOAST";
export const JOSZAKI_CREDIT_TOP_UP = "JOSZAKI_CREDIT_TOP_UP";

export default function (ctx, inject) {
  inject("joszaki", {
    alert: alert.bind(ctx),
    scrollToTop,
    searchCitySet: searchCitySet.bind(ctx),
    toast: addToast.bind(ctx),
    toastError: addToastError.bind(ctx),
    confirm: confirm.bind(ctx),
    admin: admin(ctx),
  });
}

function searchCitySet(city) {
  this.$mitt.emit(JOSZAKI_SEARCH_SET_CITY, city);
}

function admin(ctx) {
  return {
    creditDialog: creditDialog.bind(ctx),
  };
}

function creditDialog() {
  this.$mitt.emit(JOSZAKI_CREDIT_TOP_UP, {});
}

/**
 *
 * @param {string} message
 * @param {string} type - success, info, warning, error
 * @param {number} timeout in ms, default 5000
 */
function addToast({ message, type, timeout = 5000 }) {
  if (!message) {
    throw new Error("Message not supplied for toast");
  }
  this.$mitt.emit(JOSZAKI_TOAST_EVENT, { id: uuid(), message, type, timeout });
}

function addToastError(error) {
  let message = "";
  if (typeof error === "string") {
    message = error;
  } else {
    message = error?.message || this.i18n.t("error.unknown");
  }

  this.$mitt.emit(JOSZAKI_TOAST_EVENT, {
    id: uuid(),
    message,
    type: "error",
    timeout: 5000,
  });
}

function scrollToTop() {
  if (document) {
    const elem = document.getElementById("main-body");
    if (elem) {
      elem.scrollTo({ top: 0 });
    }
  }
}

function alert({ message, type }) {
  if (!message) {
    throw new Error("Message not supplied for alert");
  }
  this.$mitt.emit(JOSZAKI_ALERT_EVENT, {
    message,
    type,
  });
}

export function confirm({ message, title, onConfirm, onCancel }) {
  if (!message) {
    throw new Error("Message not supplied for alert");
  }
  if (!onConfirm) {
    throw new Error("onConfirm not supplied for alert");
  }
  this.$mitt.emit(JOSZAKI_CONFIRM_EVENT, {
    message,
    title,
    onConfirm,
    onCancel,
  });
}
