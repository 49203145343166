import { defineStore } from "pinia";
import SETTINGS_QUERY from "./settings.graphql";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    settings: {},
  }),
  getters: {
    isFavoritesDisabled() {
      return this.settings.disable_favorites === "true";
    },
    getSettings() {
      return this.settings;
    },
  },
  actions: {
    async loadSettings() {
      try {
        const result = await this.$nuxt.$query(SETTINGS_QUERY);
        console.debug("settings result", result);
        this.settings = result.settings.reduce((accumulator, currentValue) => {
          accumulator[currentValue.name] = currentValue.value;
          return accumulator;
        }, {});
      } catch (err) {
        console.error(err);
        this.$nuxt.$logger.error("Failed to load settings", err);
      }
    },
  },
});
