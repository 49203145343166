import { addIcon } from "@iconify/vue2/dist/offline";

import addressCard from "@iconify/icons-fa-solid/address-card";
import angleDoubleLeft from "@iconify/icons-fa-solid/angle-double-left";
import angleDoubleRight from "@iconify/icons-fa-solid/angle-double-right";
import angleDoubleDown from "@iconify/icons-fa-solid/angle-double-down";
import angleDown from "@iconify/icons-fa-solid/angle-down";
import angleLeft from "@iconify/icons-fa-solid/angle-left";
import angleRight from "@iconify/icons-fa-solid/angle-right";
import angleUp from "@iconify/icons-fa-solid/angle-up";
import arrowCircleRight from "@iconify/icons-fa-solid/arrow-circle-right";
import arrowDown from "@iconify/icons-fa-solid/arrow-down";
import arrowRight from "@iconify/icons-fa-solid/arrow-right";
import arrowLeft from "@iconify/icons-fa-solid/arrow-left";
import balanceScale from "@iconify/icons-fa-solid/balance-scale";
import ban from "@iconify/icons-fa-solid/ban";
import bars from "@iconify/icons-fa-solid/bars";
import bed from "@iconify/icons-fa-solid/bed";
import bell from "@iconify/icons-fa-solid/bell";
import bellSlash from "@iconify/icons-fa-solid/bell-slash";
import binoculars from "@iconify/icons-fa-solid/binoculars";
import blog from "@iconify/icons-fa-solid/blog";
import bookmark from "@iconify/icons-fa-solid/bookmark";
import briefcase from "@iconify/icons-fa-solid/briefcase";
import broom from "@iconify/icons-fa-solid/broom";
import calendar from "@iconify/icons-fa-solid/calendar";
import calendarCheck from "@iconify/icons-fa-solid/calendar-check";
import camera from "@iconify/icons-fa-solid/camera";
import car from "@iconify/icons-fa-solid/car";
import caretDown from "@iconify/icons-fa-solid/caret-down";
import cat from "@iconify/icons-fa-solid/cat";
import chartBar from "@iconify/icons-fa-solid/chart-bar";
import check from "@iconify/icons-fa-solid/check";
import checkCircle from "@iconify/icons-fa-solid/check-circle";
import checkSquare from "@iconify/icons-fa-solid/check-square";
import chevronDown from "@iconify/icons-fa-solid/chevron-down";
import chevronLeft from "@iconify/icons-fa-solid/chevron-left";
import chevronRight from "@iconify/icons-fa-solid/chevron-right";
import circle from "@iconify/icons-fa-solid/circle";
import clock from "@iconify/icons-fa-solid/clock";
import cloudDownloadAlt from "@iconify/icons-fa-solid/cloud-download-alt";
import cloudUploadAlt from "@iconify/icons-fa-solid/cloud-upload-alt";
import cog from "@iconify/icons-fa-solid/cog";
import coins from "@iconify/icons-fa-solid/coins";
import commentAlt from "@iconify/icons-fa-solid/comment-alt";
import comments from "@iconify/icons-fa-solid/comments";
import couch from "@iconify/icons-fa-solid/couch";
import crown from "@iconify/icons-fa-solid/crown";
import calculator from "@iconify/icons-fa-solid/calculator";
import dollarSign from "@iconify/icons-fa-solid/dollar-sign";
import doorClosed from "@iconify/icons-fa-solid/door-closed";
import dotCircle from "@iconify/icons-fa-solid/dot-circle";
import draftingCompass from "@iconify/icons-fa-solid/drafting-compass";
import envelope from "@iconify/icons-fa-solid/envelope";
import exchange from "@iconify/icons-fa-solid/exchange-alt";
import exclamationCircle from "@iconify/icons-fa-solid/exclamation-circle";
import exclamationTriangle from "@iconify/icons-fa-solid/exclamation-triangle";
import externalLinkAlt from "@iconify/icons-fa-solid/external-link-alt";
import eye from "@iconify/icons-fa-solid/eye";
import emptySet from "@iconify/icons-fa-solid/empty-set";
import fileUpload from "@iconify/icons-fa-solid/file-upload";
import fileInvoice from "@iconify/icons-fa-solid/file-invoice";
import fileSignature from "@iconify/icons-fa-solid/file-signature";
import funnelDollar from "@iconify/icons-fa-solid/funnel-dollar";
import gasPump from "@iconify/icons-fa-solid/gas-pump";
import gavel from "@iconify/icons-fa-solid/gavel";
import grip from "@iconify/icons-fa-solid/grip-vertical";
import handshake from "@iconify/icons-fa-solid/handshake";
import heart from "@iconify/icons-fa-solid/heart";
import hippo from "@iconify/icons-fa-solid/hippo";
import home from "@iconify/icons-fa-solid/home";
import hourglassHalf from "@iconify/icons-fa-solid/hourglass-half";
import info from "@iconify/icons-fa-solid/info";
import infoCircle from "@iconify/icons-fa-solid/info-circle";
import images from "@iconify/icons-fa-solid/images";
import listOl from "@iconify/icons-fa-solid/list-ol";
import lock from "@iconify/icons-fa-solid/lock";
import longArrowAltRight from "@iconify/icons-fa-solid/long-arrow-alt-right";
import mapMarkedAlt from "@iconify/icons-fa-solid/map-marked-alt";
import mapMarkerAlt from "@iconify/icons-fa-solid/map-marker-alt";
import mapSigns from "@iconify/icons-fa-solid/map-signs";
import magic from "@iconify/icons-fa-solid/magic";
import medal from "@iconify/icons-fa-solid/medal";
import mobileAlt from "@iconify/icons-fa-solid/mobile-alt";
import moneyBillAlt from "@iconify/icons-fa-solid/money-bill-alt";
import paperclip from "@iconify/icons-fa-solid/paperclip";
import paperPlane from "@iconify/icons-fa-solid/paper-plane";
import pen from "@iconify/icons-fa-solid/pen";
import pencilAlt from "@iconify/icons-fa-solid/pencil-alt";
import phone from "@iconify/icons-fa-solid/phone";
import phoneAlt from "@iconify/icons-fa-solid/phone-alt";
import phoneSquare from "@iconify/icons-fa-solid/phone-square";
import phoneVolume from "@iconify/icons-fa-solid/phone-volume";
import piggyBank from "@iconify/icons-fa-solid/piggy-bank";
import play from "@iconify/icons-fa-solid/play";
import plus from "@iconify/icons-fa-solid/plus";
import plusCircle from "@iconify/icons-fa-solid/plus-circle";
import puzzlePiece from "@iconify/icons-fa-solid/puzzle-piece";
import questionCircle from "@iconify/icons-fa-solid/question-circle";
import redoAlt from "@iconify/icons-fa-solid/redo-alt";
import reply from "@iconify/icons-fa-solid/reply";
import rocket from "@iconify/icons-fa-solid/rocket";
import route from "@iconify/icons-fa-solid/route";
import search from "@iconify/icons-fa-solid/search";
import shoppingBasket from "@iconify/icons-fa-solid/shopping-basket";
import shoppingCart from "@iconify/icons-fa-solid/shopping-cart";
import signature from "@iconify/icons-fa-solid/signature";
import signOutAlt from "@iconify/icons-fa-solid/sign-out-alt";
import signInAlt from "@iconify/icons-fa-solid/sign-in-alt";
import slidersH from "@iconify/icons-fa-solid/sliders-h";
import spinner from "@iconify/icons-fa-solid/spinner";
import star from "@iconify/icons-fa-solid/star";
import starHalfAlt from "@iconify/icons-fa-solid/star-half-alt";
import stopwatch from "@iconify/icons-fa-solid/stopwatch";
import tag from "@iconify/icons-fa-solid/tag";
import tasks from "@iconify/icons-fa-solid/tasks";
import thermometerEmpty from "@iconify/icons-fa-solid/thermometer-empty";
import thermometerFull from "@iconify/icons-fa-solid/thermometer-full";
import thermometerHalf from "@iconify/icons-fa-solid/thermometer-half";
import thLarge from "@iconify/icons-fa-solid/th-large";
import thumbsDown from "@iconify/icons-fa-solid/thumbs-down";
import thumbsUp from "@iconify/icons-fa-solid/thumbs-up";
import times from "@iconify/icons-fa-solid/times";
import timesCicle from "@iconify/icons-fa-solid/times-circle";
import trophy from "@iconify/icons-fa-solid/trophy";
import user from "@iconify/icons-fa-solid/user";
import userAlt from "@iconify/icons-fa-solid/user-alt";
import userCheck from "@iconify/icons-fa-solid/user-check";
import userPlus from "@iconify/icons-fa-solid/user-plus";
import userClock from "@iconify/icons-fa-solid/user-clock";
import userCog from "@iconify/icons-fa-solid/user-cog";
import userFriends from "@iconify/icons-fa-solid/user-friends";
import userTag from "@iconify/icons-fa-solid/user-tag";
import users from "@iconify/icons-fa-solid/users";
import userTimes from "@iconify/icons-fa-solid/user-times";
import trash from "@iconify/icons-fa-solid/trash";
import trashRestore from "@iconify/icons-fa-solid/trash-restore";
import wrench from "@iconify/icons-fa-solid/wrench";
import expandAlt from "@iconify/icons-fa-solid/expand-alt";
import compressAlt from "@iconify/icons-fa-solid/compress-alt";
import question from "@iconify/icons-fa-solid/question";
import percentage from "@iconify/icons-fa-solid/percentage";

import regAddressCard from "@iconify/icons-fa-regular/address-card";
import regBell from "@iconify/icons-fa-regular/bell";
import regCalendarAlt from "@iconify/icons-fa-regular/calendar-alt";
import regCheckCircle from "@iconify/icons-fa-regular/check-circle";
import regClipboard from "@iconify/icons-fa-regular/clipboard";
import regClock from "@iconify/icons-fa-regular/clock";
import regCommentAlt from "@iconify/icons-fa-regular/comment-alt";
import regCreditCard from "@iconify/icons-fa-regular/credit-card";
import regEdit from "@iconify/icons-fa-regular/edit";
import regEnvelope from "@iconify/icons-fa-regular/envelope";
import regEye from "@iconify/icons-fa-regular/eye";
import regEyeSlash from "@iconify/icons-fa-regular/eye-slash";
import regFileAlt from "@iconify/icons-fa-regular/file-alt";
import regFrown from "@iconify/icons-fa-regular/frown";
import regHeart from "@iconify/icons-fa-regular/heart";
import regIdBadge from "@iconify/icons-fa-regular/id-badge";
import regLifeRing from "@iconify/icons-fa-regular/life-ring";
import regMoneyBillAlt from "@iconify/icons-fa-regular/money-bill-alt";
import regPaperPlane from "@iconify/icons-fa-regular/paper-plane";
import regPauseCircle from "@iconify/icons-fa-regular/pause-circle";
import regQuestionCircle from "@iconify/icons-fa-regular/question-circle";
import regStar from "@iconify/icons-fa-regular/star";
import regSquare from "@iconify/icons-fa-regular/square";
import regThumbsDown from "@iconify/icons-fa-regular/thumbs-down";
import regThumbsUp from "@iconify/icons-fa-regular/thumbs-up";
import regTimesCircle from "@iconify/icons-fa-regular/times-circle";
import regTrashAlt from "@iconify/icons-fa-regular/trash-alt";
import regUser from "@iconify/icons-fa-regular/user";
import regUserCircle from "@iconify/icons-fa-regular/user-circle";

import google from "@iconify/icons-fa-brands/google";
import twitter from "@iconify/icons-fa-brands/twitter";
import facebookF from "@iconify/icons-fa-brands/facebook-f";

import skIcon from "@iconify/icons-circle-flags/sk";
import huIcon from "@iconify/icons-circle-flags/hu";
import siIcon from "@iconify/icons-circle-flags/si";
import hrIcon from "@iconify/icons-circle-flags/hr";
import rsIcon from "@iconify/icons-circle-flags/rs";
import uaIcon from "@iconify/icons-circle-flags/ua";
import czIcon from "@iconify/icons-circle-flags/cz";
import plIcon from "@iconify/icons-circle-flags/pl";
import chIcon from "@iconify/icons-circle-flags/ch";
import roIcon from "@iconify/icons-circle-flags/ro";
import atIcon from "@iconify/icons-circle-flags/at";
import deIcon from "@iconify/icons-circle-flags/de";

addIcon("fa-brands:google", google);
addIcon("fa-brands:twitter", twitter);
addIcon("fa-brands:facebook-f", facebookF);
addIcon("fa-solid:calculator", calculator);
addIcon("fa-regular:address-card", regAddressCard);
addIcon("fa-regular:bell", regBell);
addIcon("fa-regular:calendar-alt", regCalendarAlt);
addIcon("fa-regular:check-circle", regCheckCircle);
addIcon("fa-regular:clipboard", regClipboard);
addIcon("fa-regular:clock", regClock);
addIcon("fa-regular:comment-alt", regCommentAlt);
addIcon("fa-regular:credit-card", regCreditCard);
addIcon("fa-regular:edit", regEdit);
addIcon("fa-regular:envelope", regEnvelope);
addIcon("fa-regular:eye", regEye);
addIcon("fa-regular:eye-slash", regEyeSlash);
addIcon("fa-regular:file-alt", regFileAlt);
addIcon("fa-regular:frown", regFrown);
addIcon("fa-regular:heart", regHeart);
addIcon("fa-regular:id-badge", regIdBadge);
addIcon("fa-regular:life-ring", regLifeRing);
addIcon("fa-regular:money-bill-alt", regMoneyBillAlt);
addIcon("fa-regular:paper-plane", regPaperPlane);
addIcon("fa-regular:pause-circle", regPauseCircle);
addIcon("fa-regular:question-circle", regQuestionCircle);
addIcon("fa-regular:star", regStar);
addIcon("fa-regular:square", regSquare);
addIcon("fa-regular:thumbs-down", regThumbsDown);
addIcon("fa-regular:thumbs-up", regThumbsUp);
addIcon("fa-regular:times-circle", regTimesCircle);
addIcon("fa-regular:trash-alt", regTrashAlt);
addIcon("fa-regular:user-circle", regUserCircle);
addIcon("fa-regular:user", regUser);

addIcon("fa-solid:address-card", addressCard);
addIcon("fa-solid:angle-double-left", angleDoubleLeft);
addIcon("fa-solid:angle-double-right", angleDoubleRight);
addIcon("fa-solid:angle-double-down", angleDoubleDown);
addIcon("fa-solid:angle-down", angleDown);
addIcon("fa-solid:angle-left", angleLeft);
addIcon("fa-solid:angle-right", angleRight);
addIcon("fa-solid:angle-up", angleUp);
addIcon("fa-solid:arrow-circle-right", arrowCircleRight);
addIcon("fa-solid:arrow-down", arrowDown);
addIcon("fa-solid:arrow-right", arrowRight);
addIcon("fa-solid:arrow-left", arrowLeft);
addIcon("fa-solid:balance-scale", balanceScale);
addIcon("fa-solid:ban", ban);
addIcon("fa-solid:bars", bars);
addIcon("fa-solid:bed", bed);
addIcon("fa-solid:bell", bell);
addIcon("fa-solid:bell-slash", bellSlash);
addIcon("fa-solid:binoculars", binoculars);
addIcon("fa-solid:blog", blog);
addIcon("fa-solid:bookmark", bookmark);
addIcon("fa-solid:briefcase", briefcase);
addIcon("fa-solid:broom", broom);
addIcon("fa-solid:calendar-check", calendarCheck);
addIcon("fa-solid:calendar", calendar);
addIcon("fa-solid:camera", camera);
addIcon("fa-solid:car", car);
addIcon("fa-solid:caret-down", caretDown);
addIcon("fa-solid:cat", cat);
addIcon("fa-solid:chart-bar", chartBar);
addIcon("fa-solid:check-circle", checkCircle);
addIcon("fa-solid:check-square", checkSquare);
addIcon("fa-solid:check", check);
addIcon("fa-solid:chevron-down", chevronDown);
addIcon("fa-solid:chevron-left", chevronLeft);
addIcon("fa-solid:chevron-right", chevronRight);
addIcon("fa-solid:circle", circle);
addIcon("fa-solid:clock", clock);
addIcon("fa-solid:cloud-download-alt", cloudDownloadAlt);
addIcon("fa-solid:cloud-upload-alt", cloudUploadAlt);
addIcon("fa-solid:cog", cog);
addIcon("fa-solid:coins", coins);
addIcon("fa-solid:comment-alt", commentAlt);
addIcon("fa-solid:comments", comments);
addIcon("fa-solid:couch", couch);
addIcon("fa-solid:crown", crown);
addIcon("fa-solid:dollar-sign", dollarSign);
addIcon("fa-solid:door-closed", doorClosed);
addIcon("fa-solid:dot-circle", dotCircle);
addIcon("fa-solid:drafting-compass", draftingCompass);
addIcon("fa-solid:envelope", envelope);
addIcon("fa-solid:exchange", exchange);
addIcon("fa-solid:exclamation-circle", exclamationCircle);
addIcon("fa-solid:exclamation-triangle", exclamationTriangle);
addIcon("fa-solid:external-link-alt", externalLinkAlt);
addIcon("fa-solid:eye", eye);
addIcon("fa-solid:empty-set", emptySet);
addIcon("fa-solid:file-upload", fileUpload);
addIcon("fa-solid:file-invoice", fileInvoice);
addIcon("fa-solid:file-signature", fileSignature);
addIcon("fa-solid:funnel-dollar", funnelDollar);
addIcon("fa-solid:gavel", gavel);
addIcon("fa-solid:gas-pump", gasPump);
addIcon("fa-solid:grip", grip);
addIcon("fa-solid:handshake", handshake);
addIcon("fa-solid:heart", heart);
addIcon("fa-solid:hippo", hippo);
addIcon("fa-solid:home", home);
addIcon("fa-solid:hourglass-half", hourglassHalf);
addIcon("fa-solid:info-circle", infoCircle);
addIcon("fa-solid:info", info);
addIcon("fa-solid:images", images);
addIcon("fa-solid:list-ol", listOl);
addIcon("fa-solid:lock", lock);
addIcon("fa-solid:long-arrow-alt-right", longArrowAltRight);
addIcon("fa-solid:map-marked-alt", mapMarkedAlt);
addIcon("fa-solid:map-marker-alt", mapMarkerAlt);
addIcon("fa-solid:map-signs", mapSigns);
addIcon("fa-solid:magic", magic);
addIcon("fa-solid:medal", medal);
addIcon("fa-solid:mobile-alt", mobileAlt);
addIcon("fa-solid:money-bill-alt", moneyBillAlt);
addIcon("fa-solid:paper-plane", paperPlane);
addIcon("fa-solid:paperclip", paperclip);
addIcon("fa-solid:pen", pen);
addIcon("fa-solid:pencil-alt", pencilAlt);
addIcon("fa-solid:phone-alt", phoneAlt);
addIcon("fa-solid:phone-square", phoneSquare);
addIcon("fa-solid:phone-volume", phoneVolume);
addIcon("fa-solid:phone", phone);
addIcon("fa-solid:piggy-bank", piggyBank);
addIcon("fa-solid:play", play);
addIcon("fa-solid:plus-circle", plusCircle);
addIcon("fa-solid:plus", plus);
addIcon("fa-solid:puzzle-piece", puzzlePiece);
addIcon("fa-solid:question-circle", questionCircle);
addIcon("fa-solid:redo-alt", redoAlt);
addIcon("fa-solid:reply", reply);
addIcon("fa-solid:rocket", rocket);
addIcon("fa-solid:route", route);
addIcon("fa-solid:search", search);
addIcon("fa-solid:shopping-basket", shoppingBasket);
addIcon("fa-solid:shopping-cart", shoppingCart);
addIcon("fa-solid:sign-in-alt", signInAlt);
addIcon("fa-solid:sign-out-alt", signOutAlt);
addIcon("fa-solid:signature", signature);
addIcon("fa-solid:sliders-h", slidersH);
addIcon("fa-solid:spinner", spinner);
addIcon("fa-solid:star", star);
addIcon("fa-solid:star-half-alt", starHalfAlt);
addIcon("fa-solid:stopwatch", stopwatch);
addIcon("fa-solid:tag", tag);
addIcon("fa-solid:tasks", tasks);
addIcon("fa-solid:th-large", thLarge);
addIcon("fa-solid:thermometer-empty", thermometerEmpty);
addIcon("fa-solid:thermometer-full", thermometerFull);
addIcon("fa-solid:thermometer-half", thermometerHalf);
addIcon("fa-solid:thumbs-down", thumbsDown);
addIcon("fa-solid:thumbs-up", thumbsUp);
addIcon("fa-solid:times-circle", timesCicle);
addIcon("fa-solid:times", times);
addIcon("fa-solid:trophy", trophy);
addIcon("fa-solid:user-alt", userAlt);
addIcon("fa-solid:user-check", userCheck);
addIcon("fa-solid:user-plus", userPlus);
addIcon("fa-solid:user-clock", userClock);
addIcon("fa-solid:user-cog", userCog);
addIcon("fa-solid:user-times", userTimes);
addIcon("fa-solid:user", user);
addIcon("fa-solid:users", users);
addIcon("fa-solid:user-friends", userFriends);
addIcon("fa-solid:user-tag", userTag);
addIcon("fa-solid:trash", trash);
addIcon("fa-solid:trash-restore", trashRestore);
addIcon("fa-solid:wrench", wrench);
addIcon("fa-solid:expandAlt", expandAlt);
addIcon("fa-solid:compressAlt", compressAlt);
addIcon("fa-solid:question", question);
addIcon("fa-solid:percentage", percentage);

addIcon("flags:sk", skIcon);
addIcon("flags:hu", huIcon);
addIcon("flags:si", siIcon);
addIcon("flags:hr", hrIcon);
addIcon("flags:rs", rsIcon);
addIcon("flags:ua", uaIcon);
addIcon("flags:cz", czIcon);
addIcon("flags:pl", plIcon);
addIcon("flags:ch", chIcon);
addIcon("flags:ro", roIcon);
addIcon("flags:at", atIcon);
addIcon("flags:de", deIcon);
