import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a727380 = () => interopDefault(import('../pages/request-for-tender/index.vue' /* webpackChunkName: "pages/request-for-tender/index" */))
const _5751fb74 = () => interopDefault(import('../pages/customer-registration/index.vue' /* webpackChunkName: "pages/customer-registration/index" */))
const _71fcfa18 = () => interopDefault(import('../pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _0a6a041a = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _1d4b0d3b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _908b9d56 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _48caa40c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _6495c202 = () => interopDefault(import('../pages/elso-belepes/index.vue' /* webpackChunkName: "pages/elso-belepes/index" */))
const _2d173a0a = () => interopDefault(import('../pages/email-confirm/index.vue' /* webpackChunkName: "pages/email-confirm/index" */))
const _2a3e2504 = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _fb72cc32 = () => interopDefault(import('../pages/heroku-login.vue' /* webpackChunkName: "pages/heroku-login" */))
const _47206ed6 = () => interopDefault(import('../pages/inspiration/index.vue' /* webpackChunkName: "pages/inspiration/index" */))
const _00ad33c0 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _511ad2d2 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _0b624f82 = () => interopDefault(import('../pages/koszonjuk/index.vue' /* webpackChunkName: "pages/koszonjuk/index" */))
const _733c9bff = () => interopDefault(import('../pages/leiratkozas/index.vue' /* webpackChunkName: "pages/leiratkozas/index" */))
const _dd5630b6 = () => interopDefault(import('../pages/job-notification/index.vue' /* webpackChunkName: "pages/job-notification/index" */))
const _61aa34d3 = () => interopDefault(import('../pages/no-password/index.vue' /* webpackChunkName: "pages/no-password/index" */))
const _184d40c2 = () => interopDefault(import('../pages/obi.vue' /* webpackChunkName: "pages/obi" */))
const _b95be240 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _735f8a6a = () => interopDefault(import('../pages/registration/professions/index.vue' /* webpackChunkName: "pages/registration/professions/index" */))
const _524fed63 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _a1700f48 = () => interopDefault(import('../pages/registration/introduction/index.vue' /* webpackChunkName: "pages/registration/introduction/index" */))
const _500d6820 = () => interopDefault(import('../pages/registration/email/index.vue' /* webpackChunkName: "pages/registration/email/index" */))
const _ad4c4dc6 = () => interopDefault(import('../pages/registration/password/index.vue' /* webpackChunkName: "pages/registration/password/index" */))
const _b5fd23a2 = () => interopDefault(import('../pages/registration/jobfeed/index.vue' /* webpackChunkName: "pages/registration/jobfeed/index" */))
const _35ab188d = () => interopDefault(import('../pages/registration/name/index.vue' /* webpackChunkName: "pages/registration/name/index" */))
const _56af3edc = () => interopDefault(import('../pages/registration/picture/index.vue' /* webpackChunkName: "pages/registration/picture/index" */))
const _c475fd52 = () => interopDefault(import('../pages/registration/success/index.vue' /* webpackChunkName: "pages/registration/success/index" */))
const _af78cffe = () => interopDefault(import('../pages/registration/invoice/index.vue' /* webpackChunkName: "pages/registration/invoice/index" */))
const _6a739f42 = () => interopDefault(import('../pages/registration/phone/index.vue' /* webpackChunkName: "pages/registration/phone/index" */))
const _6615c126 = () => interopDefault(import('../pages/registration/city/index.vue' /* webpackChunkName: "pages/registration/city/index" */))
const _175a9af0 = () => interopDefault(import('../pages/registration/_.vue' /* webpackChunkName: "pages/registration/_" */))
const _31ae2552 = () => interopDefault(import('../pages/salaries/index.vue' /* webpackChunkName: "pages/salaries/index" */))
const _336443bc = () => interopDefault(import('../pages/szakifizu/index.vue' /* webpackChunkName: "pages/szakifizu/index" */))
const _565205b9 = () => interopDefault(import('../pages/questions/index.vue' /* webpackChunkName: "pages/questions/index" */))
const _317cd4b4 = () => interopDefault(import('../pages/profession/index.vue' /* webpackChunkName: "pages/profession/index" */))
const _3dcf3a3e = () => interopDefault(import('../pages/szerzodesmintak/index.vue' /* webpackChunkName: "pages/szerzodesmintak/index" */))
const _1709b8b2 = () => interopDefault(import('../pages/profession-tasks/index.vue' /* webpackChunkName: "pages/profession-tasks/index" */))
const _a80eaa4a = () => interopDefault(import('../pages/uj-jelszo/index.vue' /* webpackChunkName: "pages/uj-jelszo/index" */))
const _06fc26ab = () => interopDefault(import('../pages/new-professionals/index.vue' /* webpackChunkName: "pages/new-professionals/index" */))
const _6e9a3916 = () => interopDefault(import('../pages/velemeny/index.vue' /* webpackChunkName: "pages/velemeny/index" */))
const _4406f213 = () => interopDefault(import('../pages/conductor/index.vue' /* webpackChunkName: "pages/conductor/index" */))
const _b6d5b74a = () => interopDefault(import('../pages/visszajelzes/index.vue' /* webpackChunkName: "pages/visszajelzes/index" */))
const _7aa3a8ef = () => interopDefault(import('../pages/components/buttons.vue' /* webpackChunkName: "pages/components/buttons" */))
const _20d5d42a = () => interopDefault(import('../pages/components/inputs.vue' /* webpackChunkName: "pages/components/inputs" */))
const _4b5bd1ee = () => interopDefault(import('../pages/components/misc.vue' /* webpackChunkName: "pages/components/misc" */))
const _62aad9cd = () => interopDefault(import('../pages/my-account/my-tenders/index.vue' /* webpackChunkName: "pages/my-account/my-tenders/index" */))
const _3c131fce = () => interopDefault(import('../pages/my-account/prices/index.vue' /* webpackChunkName: "pages/my-account/prices/index" */))
const _6e41ee2c = () => interopDefault(import('../pages/my-account/subscription/index.vue' /* webpackChunkName: "pages/my-account/subscription/index" */))
const _64fb9938 = () => interopDefault(import('../pages/my-account/my-ratings/index.vue' /* webpackChunkName: "pages/my-account/my-ratings/index" */))
const _8d87a6a6 = () => interopDefault(import('../pages/my-account/jobs-in-progress/index.vue' /* webpackChunkName: "pages/my-account/jobs-in-progress/index" */))
const _d066ed6a = () => interopDefault(import('../pages/my-account/obtainable-jobs/index.vue' /* webpackChunkName: "pages/my-account/obtainable-jobs/index" */))
const _81489a52 = () => interopDefault(import('../pages/my-account/references/index.vue' /* webpackChunkName: "pages/my-account/references/index" */))
const _77b7d248 = () => interopDefault(import('../pages/fizetes/folyamatban.vue' /* webpackChunkName: "pages/fizetes/folyamatban" */))
const _c4e37d84 = () => interopDefault(import('../pages/fizetes/sikeres.vue' /* webpackChunkName: "pages/fizetes/sikeres" */))
const _bd5b12b8 = () => interopDefault(import('../pages/fizetes/sikertelen.vue' /* webpackChunkName: "pages/fizetes/sikertelen" */))
const _ede13eea = () => interopDefault(import('../pages/static/about-us/index.vue' /* webpackChunkName: "pages/static/about-us/index" */))
const _7a147332 = () => interopDefault(import('../pages/szakifizu/fizuk.js' /* webpackChunkName: "pages/szakifizu/fizuk" */))

const _4a2811df = () => interopDefault(import('../pages/questions/new-question/index.vue' /* webpackChunkName: "pages/questions/new-question/index" */))
const _d6bb450e = () => interopDefault(import('../pages/velemeny/koszonjuk.vue' /* webpackChunkName: "pages/velemeny/koszonjuk" */))
const _6a86b802 = () => interopDefault(import('../pages/conductor/persons/index.vue' /* webpackChunkName: "pages/conductor/persons/index" */))
const _5388a418 = () => interopDefault(import('../pages/conductor/newsletter-subscriptions/index.vue' /* webpackChunkName: "pages/conductor/newsletter-subscriptions/index" */))
const _b61144ca = () => interopDefault(import('../pages/conductor/inspirations/index.vue' /* webpackChunkName: "pages/conductor/inspirations/index" */))
const _411086de = () => interopDefault(import('../pages/conductor/questions/index.vue' /* webpackChunkName: "pages/conductor/questions/index" */))
const _3894910a = () => interopDefault(import('../pages/conductor/professions/index.vue' /* webpackChunkName: "pages/conductor/professions/index" */))
const _420dbb5f = () => interopDefault(import('../pages/visszajelzes/koszonjuk.vue' /* webpackChunkName: "pages/visszajelzes/koszonjuk" */))
const _588fe78e = () => interopDefault(import('../pages/tema/sos/festes.vue' /* webpackChunkName: "pages/tema/sos/festes" */))
const _661c0f0a = () => interopDefault(import('../pages/tema/sos/koszonjuk.vue' /* webpackChunkName: "pages/tema/sos/koszonjuk" */))
const _45db412a = () => interopDefault(import('../pages/conductor/tenders/judge/index.vue' /* webpackChunkName: "pages/conductor/tenders/judge/index" */))
const _7085908e = () => interopDefault(import('../pages/conductor/blog/articles/index.vue' /* webpackChunkName: "pages/conductor/blog/articles/index" */))
const _8ef50b2c = () => interopDefault(import('../pages/conductor/blog/tags/index.vue' /* webpackChunkName: "pages/conductor/blog/tags/index" */))
const _18885b3a = () => interopDefault(import('../pages/conductor/blog/collections/index.vue' /* webpackChunkName: "pages/conductor/blog/collections/index" */))
const _4e4d78c6 = () => interopDefault(import('../pages/conductor/inspirations/create/index.vue' /* webpackChunkName: "pages/conductor/inspirations/create/index" */))
const _691f7a34 = () => interopDefault(import('../pages/conductor/professions/create/index.vue' /* webpackChunkName: "pages/conductor/professions/create/index" */))
const _5efb2517 = () => interopDefault(import('../pages/conductor/blog/tags/create/index.vue' /* webpackChunkName: "pages/conductor/blog/tags/create/index" */))
const _c6fec31a = () => interopDefault(import('../pages/conductor/blog/articles/create/index.vue' /* webpackChunkName: "pages/conductor/blog/articles/create/index" */))
const _721e8b72 = () => interopDefault(import('../pages/conductor/blog/collections/create/index.vue' /* webpackChunkName: "pages/conductor/blog/collections/create/index" */))
const _11e7b479 = () => interopDefault(import('../pages/conductor/blog/articles/_id/index.vue' /* webpackChunkName: "pages/conductor/blog/articles/_id/index" */))
const _21e75055 = () => interopDefault(import('../pages/conductor/blog/tags/_id/index.vue' /* webpackChunkName: "pages/conductor/blog/tags/_id/index" */))
const _10ed4925 = () => interopDefault(import('../pages/conductor/blog/collections/_id/index.vue' /* webpackChunkName: "pages/conductor/blog/collections/_id/index" */))
const _a50d881c = () => interopDefault(import('../pages/blog/tag/_tagSeoName.vue' /* webpackChunkName: "pages/blog/tag/_tagSeoName" */))
const _33530cd6 = () => interopDefault(import('../pages/blog/collection/_collectionSeoName.vue' /* webpackChunkName: "pages/blog/collection/_collectionSeoName" */))
const _25c6579a = () => interopDefault(import('../pages/blog/profession/_professionSeoName.vue' /* webpackChunkName: "pages/blog/profession/_professionSeoName" */))
const _4ca8bc10 = () => interopDefault(import('../pages/blog/professionTask/_professionTaskSeoName.vue' /* webpackChunkName: "pages/blog/professionTask/_professionTaskSeoName" */))
const _0b387cfc = () => interopDefault(import('../pages/my-account/settings/_highlightedSetting.vue' /* webpackChunkName: "pages/my-account/settings/_highlightedSetting" */))
const _533c1a7c = () => interopDefault(import('../pages/my-account/my-job/_personTenderId.vue' /* webpackChunkName: "pages/my-account/my-job/_personTenderId" */))
const _1ffae464 = () => interopDefault(import('../pages/inspiration/profession/_professionSeoName.vue' /* webpackChunkName: "pages/inspiration/profession/_professionSeoName" */))
const _67413166 = () => interopDefault(import('../pages/inspiration/profession-task/_professionTaskSeoName.vue' /* webpackChunkName: "pages/inspiration/profession-task/_professionTaskSeoName" */))
const _5505892c = () => interopDefault(import('../pages/szakifizu/atlag/_szakmaSeo/index.vue' /* webpackChunkName: "pages/szakifizu/atlag/_szakmaSeo/index" */))
const _3979a7c4 = () => interopDefault(import('../pages/questions' /* webpackChunkName: "pages/questions" */))
const _4f6001ed = () => interopDefault(import('../pages/conductor/persons/_id/index.vue' /* webpackChunkName: "pages/conductor/persons/_id/index" */))
const _213b99f4 = () => interopDefault(import('../pages/conductor/inspirations/_id/index.vue' /* webpackChunkName: "pages/conductor/inspirations/_id/index" */))
const _240e26fc = () => interopDefault(import('../pages/conductor/questions/_id/index.vue' /* webpackChunkName: "pages/conductor/questions/_id/index" */))
const _dcbec634 = () => interopDefault(import('../pages/conductor/professions/_id/index.vue' /* webpackChunkName: "pages/conductor/professions/_id/index" */))
const _7420f8b0 = () => interopDefault(import('../pages/szakifizu/atlag/_szakmaSeo/munkak/index.vue' /* webpackChunkName: "pages/szakifizu/atlag/_szakmaSeo/munkak/index" */))
const _3e34b71c = () => interopDefault(import('../pages/request-for-tender/_activeTab.vue' /* webpackChunkName: "pages/request-for-tender/_activeTab" */))
const _8b1fad3c = () => interopDefault(import('../pages/prices/_professionTask.vue' /* webpackChunkName: "pages/prices/_professionTask" */))
const _1a9412cc = () => interopDefault(import('../pages/arcsekkolo/_professionSeoName/index.vue' /* webpackChunkName: "pages/arcsekkolo/_professionSeoName/index" */))
const _759b63ea = () => interopDefault(import('../pages/blog/_articleSeoName.vue' /* webpackChunkName: "pages/blog/_articleSeoName" */))
const _62fd8224 = () => interopDefault(import('../pages/inspiration/_inspirationSeoName/index.vue' /* webpackChunkName: "pages/inspiration/_inspirationSeoName/index" */))
const _00df2ea8 = () => interopDefault(import('../pages/counties/_county/index.vue' /* webpackChunkName: "pages/counties/_county/index" */))
const _5981deac = () => interopDefault(import('../pages/partner-ajanlatkeres/_partnerCode.vue' /* webpackChunkName: "pages/partner-ajanlatkeres/_partnerCode" */))
const _58c66acc = () => interopDefault(import('../pages/projects/_id.vue' /* webpackChunkName: "pages/projects/_id" */))
const _40a8adb6 = () => interopDefault(import('../pages/professional/_professionalSeoName/index.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/index" */))
const _8f771e38 = () => interopDefault(import('../pages/professionals/_profession/index.vue' /* webpackChunkName: "pages/professionals/_profession/index" */))
const _1536bb6f = () => interopDefault(import('../pages/questions/_questionSeoName/index.vue' /* webpackChunkName: "pages/questions/_questionSeoName/index" */))
const _ee6be1fa = () => interopDefault(import('../pages/profession/_professionGroup/index.vue' /* webpackChunkName: "pages/profession/_professionGroup/index" */))
const _c819e8c6 = () => interopDefault(import('../pages/tender/_tenderId/index.vue' /* webpackChunkName: "pages/tender/_tenderId/index" */))
const _a1b16392 = () => interopDefault(import('../pages/valassz/_decisionTestSeoName.vue' /* webpackChunkName: "pages/valassz/_decisionTestSeoName" */))
const _502ba96f = () => interopDefault(import('../pages/tender/_tenderId/specify/index.vue' /* webpackChunkName: "pages/tender/_tenderId/specify/index" */))
const _d01b40a8 = () => interopDefault(import('../pages/professional/_professionalSeoName/rating/index.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/rating/index" */))
const _23a89800 = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/balance.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/balance" */))
const _08378e5c = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/merge.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/merge" */))
const _006ec025 = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/person-event-log.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/person-event-log" */))
const _269d9be8 = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/transfers.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/transfers" */))
const _c108683a = () => interopDefault(import('../pages/tender/_tenderId/specify/_activeTab.vue' /* webpackChunkName: "pages/tender/_tenderId/specify/_activeTab" */))
const _15c88c18 = () => interopDefault(import('../pages/professionals/_profession/szakivalaszol/_questionSeoName.vue' /* webpackChunkName: "pages/professionals/_profession/szakivalaszol/_questionSeoName" */))
const _3ebeabe0 = () => interopDefault(import('../pages/arcsekkolo/_professionSeoName/_priceTagSeoName.vue' /* webpackChunkName: "pages/arcsekkolo/_professionSeoName/_priceTagSeoName" */))
const _e9c1468c = () => interopDefault(import('../pages/price-calculator/_professionTask/_step.vue' /* webpackChunkName: "pages/price-calculator/_professionTask/_step" */))
const _4b7e0f5e = () => interopDefault(import('../pages/inspiration/_inspirationSeoName/_pictureSeoName/index.vue' /* webpackChunkName: "pages/inspiration/_inspirationSeoName/_pictureSeoName/index" */))
const _cdddc348 = () => interopDefault(import('../pages/professionals/_profession/_city.vue' /* webpackChunkName: "pages/professionals/_profession/_city" */))
const _a8282a72 = () => interopDefault(import('../pages/counties/_county/_city/_subCity.vue' /* webpackChunkName: "pages/counties/_county/_city/_subCity" */))
const _2d222478 = () => interopDefault(import('../pages/professionals/_profession/_city' /* webpackChunkName: "pages/professionals/_profession/_parentCity/_city" */))
const _03cf66c8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ajanlatkeres",
    component: _5a727380,
    name: "requestForTender___hu"
  }, {
    path: "/ajanlatkeroi-regisztracio",
    component: _5751fb74,
    name: "customerRegistrationPage___hu"
  }, {
    path: "/arak",
    component: _71fcfa18,
    name: "pricesPage___hu"
  }, {
    path: "/aszf",
    component: _0a6a041a,
    name: "terms___hu"
  }, {
    path: "/at",
    component: _1d4b0d3b,
    name: "indexPage___at"
  }, {
    path: "/belepes",
    component: _908b9d56,
    name: "login___hu"
  }, {
    path: "/blog",
    component: _48caa40c,
    name: "blogLandingPage___hu"
  }, {
    path: "/cs",
    component: _1d4b0d3b,
    name: "indexPage___cs"
  }, {
    path: "/de",
    component: _1d4b0d3b,
    name: "indexPage___de"
  }, {
    path: "/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___hu"
  }, {
    path: "/email-megerosites",
    component: _2d173a0a,
    name: "emailConfirmPage___hu"
  }, {
    path: "/en",
    component: _1d4b0d3b,
    name: "indexPage___en"
  }, {
    path: "/health-check",
    component: _2a3e2504,
    name: "health-check___hu"
  }, {
    path: "/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___hu"
  }, {
    path: "/inspiracio",
    component: _47206ed6,
    name: "inspirationLandingPage___hu"
  }, {
    path: "/kedvencek",
    component: _00ad33c0,
    name: "favorites___hu"
  }, {
    path: "/kereses",
    component: _511ad2d2,
    name: "search___hu"
  }, {
    path: "/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___hu"
  }, {
    path: "/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___hu"
  }, {
    path: "/munkaertesito",
    component: _dd5630b6,
    name: "jobNotificationPage___hu"
  }, {
    path: "/nincs-jelszo",
    component: _61aa34d3,
    name: "noPassword___hu"
  }, {
    path: "/obi",
    component: _184d40c2,
    name: "obi___hu"
  }, {
    path: "/regisztracio",
    component: _b95be240,
    name: "registrationPage___hu",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___hu"
    }, {
      path: "/regisztracio",
      component: _524fed63,
      name: "registration___hu"
    }, {
      path: "/regisztracio/bemutatkozas",
      component: _a1700f48,
      name: "registrationStepIntroduction___hu"
    }, {
      path: "/regisztracio/email",
      component: _500d6820,
      name: "registrationStepEmail___hu"
    }, {
      path: "/regisztracio/jelszo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___hu"
    }, {
      path: "/regisztracio/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___hu"
    }, {
      path: "/regisztracio/nev",
      component: _35ab188d,
      name: "registrationStepName___hu"
    }, {
      path: "/regisztracio/profilkep",
      component: _56af3edc,
      name: "registrationStepPicture___hu"
    }, {
      path: "/regisztracio/siker",
      component: _c475fd52,
      name: "registrationStepSuccess___hu"
    }, {
      path: "/regisztracio/szamlakepesseg",
      component: _af78cffe,
      name: "registrationStepInvoice___hu"
    }, {
      path: "/regisztracio/telefonszam",
      component: _6a739f42,
      name: "registrationStepPhone___hu"
    }, {
      path: "/regisztracio/varos",
      component: _6615c126,
      name: "registrationStepCity___hu"
    }, {
      path: "/regisztracio/:_",
      component: _175a9af0,
      name: "registration-all___hu"
    }]
  }, {
    path: "/ro",
    component: _1d4b0d3b,
    name: "indexPage___ro"
  }, {
    path: "/szakifizetesek",
    component: _31ae2552,
    name: "salariesPage___hu"
  }, {
    path: "/szakifizu",
    component: _336443bc,
    name: "szakifizu___hu"
  }, {
    path: "/szakivalaszol",
    component: _565205b9,
    name: "questionsPage___hu"
  }, {
    path: "/szakma",
    component: _317cd4b4,
    name: "professionsLandingPage___hu"
  }, {
    path: "/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___hu"
  }, {
    path: "/tevekenysegek",
    component: _1709b8b2,
    name: "profession-tasks___hu"
  }, {
    path: "/uj-jelszo",
    component: _a80eaa4a,
    name: "uj-jelszo___hu"
  }, {
    path: "/uj-szakik",
    component: _06fc26ab,
    name: "new-professionals___hu"
  }, {
    path: "/velemeny",
    component: _6e9a3916,
    name: "velemeny___hu"
  }, {
    path: "/vezerlo",
    component: _4406f213,
    name: "conductorIndexPage___hu"
  }, {
    path: "/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___hu"
  }, {
    path: "/at/ajanlatkeres",
    component: _5a727380,
    name: "requestForTender___at"
  }, {
    path: "/at/ajanlatkeroi-regisztracio",
    component: _5751fb74,
    name: "customerRegistrationPage___at"
  }, {
    path: "/at/arak",
    component: _71fcfa18,
    name: "pricesPage___at"
  }, {
    path: "/at/aszf",
    component: _0a6a041a,
    name: "terms___at"
  }, {
    path: "/at/belepes",
    component: _908b9d56,
    name: "login___at"
  }, {
    path: "/at/blog",
    component: _48caa40c,
    name: "blogLandingPage___at"
  }, {
    path: "/at/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___at"
  }, {
    path: "/at/email-megerosites",
    component: _2d173a0a,
    name: "emailConfirmPage___at"
  }, {
    path: "/at/health-check",
    component: _2a3e2504,
    name: "health-check___at"
  }, {
    path: "/at/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___at"
  }, {
    path: "/at/inspiracio",
    component: _47206ed6,
    name: "inspirationLandingPage___at"
  }, {
    path: "/at/kedvencek",
    component: _00ad33c0,
    name: "favorites___at"
  }, {
    path: "/at/kereses",
    component: _511ad2d2,
    name: "search___at"
  }, {
    path: "/at/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___at"
  }, {
    path: "/at/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___at"
  }, {
    path: "/at/munkaertesito",
    component: _dd5630b6,
    name: "jobNotificationPage___at"
  }, {
    path: "/at/nincs-jelszo",
    component: _61aa34d3,
    name: "noPassword___at"
  }, {
    path: "/at/obi",
    component: _184d40c2,
    name: "obi___at"
  }, {
    path: "/at/regisztracio",
    component: _b95be240,
    name: "registrationPage___at",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___at"
    }, {
      path: "/at/regisztracio",
      component: _524fed63,
      name: "registration___at"
    }, {
      path: "/at/regisztracio/bemutatkozas",
      component: _a1700f48,
      name: "registrationStepIntroduction___at"
    }, {
      path: "/at/regisztracio/email",
      component: _500d6820,
      name: "registrationStepEmail___at"
    }, {
      path: "/at/regisztracio/jelszo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___at"
    }, {
      path: "/at/regisztracio/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___at"
    }, {
      path: "/at/regisztracio/nev",
      component: _35ab188d,
      name: "registrationStepName___at"
    }, {
      path: "/at/regisztracio/profilkep",
      component: _56af3edc,
      name: "registrationStepPicture___at"
    }, {
      path: "/at/regisztracio/siker",
      component: _c475fd52,
      name: "registrationStepSuccess___at"
    }, {
      path: "/at/regisztracio/szamlakepesseg",
      component: _af78cffe,
      name: "registrationStepInvoice___at"
    }, {
      path: "/at/regisztracio/telefonszam",
      component: _6a739f42,
      name: "registrationStepPhone___at"
    }, {
      path: "/at/regisztracio/varos",
      component: _6615c126,
      name: "registrationStepCity___at"
    }, {
      path: "/at/regisztracio/:_",
      component: _175a9af0,
      name: "registration-all___at"
    }]
  }, {
    path: "/at/szakifizetesek",
    component: _31ae2552,
    name: "salariesPage___at"
  }, {
    path: "/at/szakifizu",
    component: _336443bc,
    name: "szakifizu___at"
  }, {
    path: "/at/szakivalaszol",
    component: _565205b9,
    name: "questionsPage___at"
  }, {
    path: "/at/szakma",
    component: _317cd4b4,
    name: "professionsLandingPage___at"
  }, {
    path: "/at/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___at"
  }, {
    path: "/at/tevekenysegek",
    component: _1709b8b2,
    name: "profession-tasks___at"
  }, {
    path: "/at/uj-jelszo",
    component: _a80eaa4a,
    name: "uj-jelszo___at"
  }, {
    path: "/at/uj-szakik",
    component: _06fc26ab,
    name: "new-professionals___at"
  }, {
    path: "/at/velemeny",
    component: _6e9a3916,
    name: "velemeny___at"
  }, {
    path: "/at/vezerlo",
    component: _4406f213,
    name: "conductorIndexPage___at"
  }, {
    path: "/at/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___at"
  }, {
    path: "/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___hu"
  }, {
    path: "/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___hu"
  }, {
    path: "/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___hu"
  }, {
    path: "/cs/ajanlatkeroi-regisztracio",
    component: _5751fb74,
    name: "customerRegistrationPage___cs"
  }, {
    path: "/cs/blog",
    component: _48caa40c,
    name: "blogLandingPage___cs"
  }, {
    path: "/cs/ceny",
    component: _71fcfa18,
    name: "pricesPage___cs"
  }, {
    path: "/cs/conductor",
    component: _4406f213,
    name: "conductorIndexPage___cs"
  }, {
    path: "/cs/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___cs"
  }, {
    path: "/cs/health-check",
    component: _2a3e2504,
    name: "health-check___cs"
  }, {
    path: "/cs/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___cs"
  }, {
    path: "/cs/inspirace",
    component: _47206ed6,
    name: "inspirationLandingPage___cs"
  }, {
    path: "/cs/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___cs"
  }, {
    path: "/cs/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___cs"
  }, {
    path: "/cs/munkaertesito",
    component: _dd5630b6,
    name: "jobNotificationPage___cs"
  }, {
    path: "/cs/nincs-jelszo",
    component: _61aa34d3,
    name: "noPassword___cs"
  }, {
    path: "/cs/obi",
    component: _184d40c2,
    name: "obi___cs"
  }, {
    path: "/cs/oblibene",
    component: _00ad33c0,
    name: "favorites___cs"
  }, {
    path: "/cs/platy",
    component: _31ae2552,
    name: "salariesPage___cs"
  }, {
    path: "/cs/potvrzeni-emailu",
    component: _2d173a0a,
    name: "emailConfirmPage___cs"
  }, {
    path: "/cs/prihlaseni",
    component: _908b9d56,
    name: "login___cs"
  }, {
    path: "/cs/profese",
    component: _317cd4b4,
    name: "professionsLandingPage___cs"
  }, {
    path: "/cs/registrace",
    component: _b95be240,
    name: "registrationPage___cs",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___cs"
    }, {
      path: "/cs/registrace",
      component: _524fed63,
      name: "registration___cs"
    }, {
      path: "/cs/registrace/email",
      component: _500d6820,
      name: "registrationStepEmail___cs"
    }, {
      path: "/cs/registrace/fakturovani",
      component: _af78cffe,
      name: "registrationStepInvoice___cs"
    }, {
      path: "/cs/registrace/heslo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___cs"
    }, {
      path: "/cs/registrace/jmeno",
      component: _35ab188d,
      name: "registrationStepName___cs"
    }, {
      path: "/cs/registrace/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___cs"
    }, {
      path: "/cs/registrace/mesto",
      component: _6615c126,
      name: "registrationStepCity___cs"
    }, {
      path: "/cs/registrace/predstaveni",
      component: _a1700f48,
      name: "registrationStepIntroduction___cs"
    }, {
      path: "/cs/registrace/profilovka",
      component: _56af3edc,
      name: "registrationStepPicture___cs"
    }, {
      path: "/cs/registrace/tel-cislo",
      component: _6a739f42,
      name: "registrationStepPhone___cs"
    }, {
      path: "/cs/registrace/uspech",
      component: _c475fd52,
      name: "registrationStepSuccess___cs"
    }, {
      path: "/cs/registrace/:_",
      component: _175a9af0,
      name: "registration-all___cs"
    }]
  }, {
    path: "/cs/szakifizu",
    component: _336443bc,
    name: "szakifizu___cs"
  }, {
    path: "/cs/szakivalaszol",
    component: _565205b9,
    name: "questionsPage___cs"
  }, {
    path: "/cs/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___cs"
  }, {
    path: "/cs/tevekenysegek",
    component: _1709b8b2,
    name: "profession-tasks___cs"
  }, {
    path: "/cs/uj-jelszo",
    component: _a80eaa4a,
    name: "uj-jelszo___cs"
  }, {
    path: "/cs/uj-szakik",
    component: _06fc26ab,
    name: "new-professionals___cs"
  }, {
    path: "/cs/velemeny",
    component: _6e9a3916,
    name: "velemeny___cs"
  }, {
    path: "/cs/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___cs"
  }, {
    path: "/cs/vop",
    component: _0a6a041a,
    name: "terms___cs"
  }, {
    path: "/cs/vyhledavani",
    component: _511ad2d2,
    name: "search___cs"
  }, {
    path: "/cs/zadost-o-nabidku",
    component: _5a727380,
    name: "requestForTender___cs"
  }, {
    path: "/de/ajanlatkeres",
    component: _5a727380,
    name: "requestForTender___de"
  }, {
    path: "/de/ajanlatkeroi-regisztracio",
    component: _5751fb74,
    name: "customerRegistrationPage___de"
  }, {
    path: "/de/arak",
    component: _71fcfa18,
    name: "pricesPage___de"
  }, {
    path: "/de/aszf",
    component: _0a6a041a,
    name: "terms___de"
  }, {
    path: "/de/belepes",
    component: _908b9d56,
    name: "login___de"
  }, {
    path: "/de/blog",
    component: _48caa40c,
    name: "blogLandingPage___de"
  }, {
    path: "/de/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___de"
  }, {
    path: "/de/email-megerosites",
    component: _2d173a0a,
    name: "emailConfirmPage___de"
  }, {
    path: "/de/health-check",
    component: _2a3e2504,
    name: "health-check___de"
  }, {
    path: "/de/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___de"
  }, {
    path: "/de/inspiracio",
    component: _47206ed6,
    name: "inspirationLandingPage___de"
  }, {
    path: "/de/kedvencek",
    component: _00ad33c0,
    name: "favorites___de"
  }, {
    path: "/de/kereses",
    component: _511ad2d2,
    name: "search___de"
  }, {
    path: "/de/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___de"
  }, {
    path: "/de/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___de"
  }, {
    path: "/de/munkaertesito",
    component: _dd5630b6,
    name: "jobNotificationPage___de"
  }, {
    path: "/de/nincs-jelszo",
    component: _61aa34d3,
    name: "noPassword___de"
  }, {
    path: "/de/obi",
    component: _184d40c2,
    name: "obi___de"
  }, {
    path: "/de/regisztracio",
    component: _b95be240,
    name: "registrationPage___de",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___de"
    }, {
      path: "/de/regisztracio",
      component: _524fed63,
      name: "registration___de"
    }, {
      path: "/de/regisztracio/bemutatkozas",
      component: _a1700f48,
      name: "registrationStepIntroduction___de"
    }, {
      path: "/de/regisztracio/email",
      component: _500d6820,
      name: "registrationStepEmail___de"
    }, {
      path: "/de/regisztracio/jelszo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___de"
    }, {
      path: "/de/regisztracio/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___de"
    }, {
      path: "/de/regisztracio/nev",
      component: _35ab188d,
      name: "registrationStepName___de"
    }, {
      path: "/de/regisztracio/profilkep",
      component: _56af3edc,
      name: "registrationStepPicture___de"
    }, {
      path: "/de/regisztracio/siker",
      component: _c475fd52,
      name: "registrationStepSuccess___de"
    }, {
      path: "/de/regisztracio/szamlakepesseg",
      component: _af78cffe,
      name: "registrationStepInvoice___de"
    }, {
      path: "/de/regisztracio/telefonszam",
      component: _6a739f42,
      name: "registrationStepPhone___de"
    }, {
      path: "/de/regisztracio/varos",
      component: _6615c126,
      name: "registrationStepCity___de"
    }, {
      path: "/de/regisztracio/:_",
      component: _175a9af0,
      name: "registration-all___de"
    }]
  }, {
    path: "/de/szakifizetesek",
    component: _31ae2552,
    name: "salariesPage___de"
  }, {
    path: "/de/szakifizu",
    component: _336443bc,
    name: "szakifizu___de"
  }, {
    path: "/de/szakivalaszol",
    component: _565205b9,
    name: "questionsPage___de"
  }, {
    path: "/de/szakma",
    component: _317cd4b4,
    name: "professionsLandingPage___de"
  }, {
    path: "/de/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___de"
  }, {
    path: "/de/tevekenysegek",
    component: _1709b8b2,
    name: "profession-tasks___de"
  }, {
    path: "/de/uj-jelszo",
    component: _a80eaa4a,
    name: "uj-jelszo___de"
  }, {
    path: "/de/uj-szakik",
    component: _06fc26ab,
    name: "new-professionals___de"
  }, {
    path: "/de/velemeny",
    component: _6e9a3916,
    name: "velemeny___de"
  }, {
    path: "/de/vezerlo",
    component: _4406f213,
    name: "conductorIndexPage___de"
  }, {
    path: "/de/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___de"
  }, {
    path: "/en/blog",
    component: _48caa40c,
    name: "blogLandingPage___en"
  }, {
    path: "/en/conductor",
    component: _4406f213,
    name: "conductorIndexPage___en"
  }, {
    path: "/en/customer-registration",
    component: _5751fb74,
    name: "customerRegistrationPage___en"
  }, {
    path: "/en/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___en"
  }, {
    path: "/en/email-confirm",
    component: _2d173a0a,
    name: "emailConfirmPage___en"
  }, {
    path: "/en/favorites",
    component: _00ad33c0,
    name: "favorites___en"
  }, {
    path: "/en/health-check",
    component: _2a3e2504,
    name: "health-check___en"
  }, {
    path: "/en/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___en"
  }, {
    path: "/en/inspiration",
    component: _47206ed6,
    name: "inspirationLandingPage___en"
  }, {
    path: "/en/job-notification",
    component: _dd5630b6,
    name: "jobNotificationPage___en"
  }, {
    path: "/en/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___en"
  }, {
    path: "/en/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___en"
  }, {
    path: "/en/login",
    component: _908b9d56,
    name: "login___en"
  }, {
    path: "/en/new-professionals",
    component: _06fc26ab,
    name: "new-professionals___en"
  }, {
    path: "/en/no-password",
    component: _61aa34d3,
    name: "noPassword___en"
  }, {
    path: "/en/obi",
    component: _184d40c2,
    name: "obi___en"
  }, {
    path: "/en/prices",
    component: _71fcfa18,
    name: "pricesPage___en"
  }, {
    path: "/en/profession",
    component: _317cd4b4,
    name: "professionsLandingPage___en"
  }, {
    path: "/en/profession-tasks",
    component: _1709b8b2,
    name: "profession-tasks___en"
  }, {
    path: "/en/questions",
    component: _565205b9,
    name: "questionsPage___en"
  }, {
    path: "/en/registration",
    component: _b95be240,
    name: "registrationPage___en",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___en"
    }, {
      path: "/en/registration",
      component: _524fed63,
      name: "registration___en"
    }, {
      path: "/en/registration/city",
      component: _6615c126,
      name: "registrationStepCity___en"
    }, {
      path: "/en/registration/email",
      component: _500d6820,
      name: "registrationStepEmail___en"
    }, {
      path: "/en/registration/introduction",
      component: _a1700f48,
      name: "registrationStepIntroduction___en"
    }, {
      path: "/en/registration/invoice",
      component: _af78cffe,
      name: "registrationStepInvoice___en"
    }, {
      path: "/en/registration/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___en"
    }, {
      path: "/en/registration/name",
      component: _35ab188d,
      name: "registrationStepName___en"
    }, {
      path: "/en/registration/password",
      component: _ad4c4dc6,
      name: "registrationStepPassword___en"
    }, {
      path: "/en/registration/phone",
      component: _6a739f42,
      name: "registrationStepPhone___en"
    }, {
      path: "/en/registration/profile-pic",
      component: _56af3edc,
      name: "registrationStepPicture___en"
    }, {
      path: "/en/registration/success",
      component: _c475fd52,
      name: "registrationStepSuccess___en"
    }, {
      path: "/en/registration/:_",
      component: _175a9af0,
      name: "registration-all___en"
    }]
  }, {
    path: "/en/request-for-tender",
    component: _5a727380,
    name: "requestForTender___en"
  }, {
    path: "/en/salaries",
    component: _31ae2552,
    name: "salariesPage___en"
  }, {
    path: "/en/search",
    component: _511ad2d2,
    name: "search___en"
  }, {
    path: "/en/szakifizu",
    component: _336443bc,
    name: "szakifizu___en"
  }, {
    path: "/en/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___en"
  }, {
    path: "/en/terms",
    component: _0a6a041a,
    name: "terms___en"
  }, {
    path: "/en/uj-jelszo",
    component: _a80eaa4a,
    name: "uj-jelszo___en"
  }, {
    path: "/en/velemeny",
    component: _6e9a3916,
    name: "velemeny___en"
  }, {
    path: "/en/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___en"
  }, {
    path: "/fiokom/ajanlatkereseim",
    component: _62aad9cd,
    name: "myTenders___hu"
  }, {
    path: "/fiokom/araim",
    component: _3c131fce,
    name: "addPricesPage___hu"
  }, {
    path: "/fiokom/elofizetes",
    component: _6e41ee2c,
    name: "subscriptionPage___hu"
  }, {
    path: "/fiokom/ertekeleseim",
    component: _64fb9938,
    name: "RatingsPage___hu"
  }, {
    path: "/fiokom/folyamatban-levo-munkaim",
    component: _8d87a6a6,
    name: "boughtTendersPage___hu"
  }, {
    path: "/fiokom/megszerezheto-munkaim",
    component: _d066ed6a,
    name: "buyTendersPage___hu"
  }, {
    path: "/fiokom/referenciaim",
    component: _81489a52,
    name: "references___hu"
  }, {
    path: "/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___hu"
  }, {
    path: "/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___hu"
  }, {
    path: "/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___hu"
  }, {
    path: "/ro/ajanlatkeres",
    component: _5a727380,
    name: "requestForTender___ro"
  }, {
    path: "/ro/ajanlatkeroi-regisztracio",
    component: _5751fb74,
    name: "customerRegistrationPage___ro"
  }, {
    path: "/ro/arak",
    component: _71fcfa18,
    name: "pricesPage___ro"
  }, {
    path: "/ro/aszf",
    component: _0a6a041a,
    name: "terms___ro"
  }, {
    path: "/ro/belepes",
    component: _908b9d56,
    name: "login___ro"
  }, {
    path: "/ro/blog",
    component: _48caa40c,
    name: "blogLandingPage___ro"
  }, {
    path: "/ro/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___ro"
  }, {
    path: "/ro/email-megerosites",
    component: _2d173a0a,
    name: "emailConfirmPage___ro"
  }, {
    path: "/ro/health-check",
    component: _2a3e2504,
    name: "health-check___ro"
  }, {
    path: "/ro/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___ro"
  }, {
    path: "/ro/inspiracio",
    component: _47206ed6,
    name: "inspirationLandingPage___ro"
  }, {
    path: "/ro/kedvencek",
    component: _00ad33c0,
    name: "favorites___ro"
  }, {
    path: "/ro/kereses",
    component: _511ad2d2,
    name: "search___ro"
  }, {
    path: "/ro/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___ro"
  }, {
    path: "/ro/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___ro"
  }, {
    path: "/ro/munkaertesito",
    component: _dd5630b6,
    name: "jobNotificationPage___ro"
  }, {
    path: "/ro/nincs-jelszo",
    component: _61aa34d3,
    name: "noPassword___ro"
  }, {
    path: "/ro/obi",
    component: _184d40c2,
    name: "obi___ro"
  }, {
    path: "/ro/regisztracio",
    component: _b95be240,
    name: "registrationPage___ro",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___ro"
    }, {
      path: "/ro/regisztracio",
      component: _524fed63,
      name: "registration___ro"
    }, {
      path: "/ro/regisztracio/bemutatkozas",
      component: _a1700f48,
      name: "registrationStepIntroduction___ro"
    }, {
      path: "/ro/regisztracio/email",
      component: _500d6820,
      name: "registrationStepEmail___ro"
    }, {
      path: "/ro/regisztracio/jelszo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___ro"
    }, {
      path: "/ro/regisztracio/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___ro"
    }, {
      path: "/ro/regisztracio/nev",
      component: _35ab188d,
      name: "registrationStepName___ro"
    }, {
      path: "/ro/regisztracio/profilkep",
      component: _56af3edc,
      name: "registrationStepPicture___ro"
    }, {
      path: "/ro/regisztracio/siker",
      component: _c475fd52,
      name: "registrationStepSuccess___ro"
    }, {
      path: "/ro/regisztracio/szamlakepesseg",
      component: _af78cffe,
      name: "registrationStepInvoice___ro"
    }, {
      path: "/ro/regisztracio/telefonszam",
      component: _6a739f42,
      name: "registrationStepPhone___ro"
    }, {
      path: "/ro/regisztracio/varos",
      component: _6615c126,
      name: "registrationStepCity___ro"
    }, {
      path: "/ro/regisztracio/:_",
      component: _175a9af0,
      name: "registration-all___ro"
    }]
  }, {
    path: "/ro/szakifizetesek",
    component: _31ae2552,
    name: "salariesPage___ro"
  }, {
    path: "/ro/szakifizu",
    component: _336443bc,
    name: "szakifizu___ro"
  }, {
    path: "/ro/szakivalaszol",
    component: _565205b9,
    name: "questionsPage___ro"
  }, {
    path: "/ro/szakma",
    component: _317cd4b4,
    name: "professionsLandingPage___ro"
  }, {
    path: "/ro/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___ro"
  }, {
    path: "/ro/tevekenysegek",
    component: _1709b8b2,
    name: "profession-tasks___ro"
  }, {
    path: "/ro/uj-jelszo",
    component: _a80eaa4a,
    name: "uj-jelszo___ro"
  }, {
    path: "/ro/uj-szakik",
    component: _06fc26ab,
    name: "new-professionals___ro"
  }, {
    path: "/ro/velemeny",
    component: _6e9a3916,
    name: "velemeny___ro"
  }, {
    path: "/ro/vezerlo",
    component: _4406f213,
    name: "conductorIndexPage___ro"
  }, {
    path: "/ro/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___ro"
  }, {
    path: "/static/rolunk",
    component: _ede13eea,
    name: "aboutUs___hu"
  }, {
    path: "/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___hu"
  }, {
    path: "/szakivalaszol/szakma",
    redirect: "/questions"
  }, {
    path: "/szakivalaszol/tevekenyseg",
    redirect: "/questions"
  }, {
    path: "/szakivalaszol/uj-kerdes",
    component: _4a2811df,
    name: "addQuestionPage___hu"
  }, {
    path: "/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___hu"
  }, {
    path: "/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___hu"
  }, {
    path: "/vezerlo/hirlevel-feliratkozasok",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___hu"
  }, {
    path: "/vezerlo/inspiraciok",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___hu"
  }, {
    path: "/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___hu"
  }, {
    path: "/vezerlo/szakmak",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___hu"
  }, {
    path: "/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___hu"
  }, {
    path: "/at/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___at"
  }, {
    path: "/at/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___at"
  }, {
    path: "/at/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___at"
  }, {
    path: "/at/fiokom/ajanlatkereseim",
    component: _62aad9cd,
    name: "myTenders___at"
  }, {
    path: "/at/fiokom/araim",
    component: _3c131fce,
    name: "addPricesPage___at"
  }, {
    path: "/at/fiokom/elofizetes",
    component: _6e41ee2c,
    name: "subscriptionPage___at"
  }, {
    path: "/at/fiokom/ertekeleseim",
    component: _64fb9938,
    name: "RatingsPage___at"
  }, {
    path: "/at/fiokom/folyamatban-levo-munkaim",
    component: _8d87a6a6,
    name: "boughtTendersPage___at"
  }, {
    path: "/at/fiokom/megszerezheto-munkaim",
    component: _d066ed6a,
    name: "buyTendersPage___at"
  }, {
    path: "/at/fiokom/referenciaim",
    component: _81489a52,
    name: "references___at"
  }, {
    path: "/at/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___at"
  }, {
    path: "/at/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___at"
  }, {
    path: "/at/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___at"
  }, {
    path: "/at/static/rolunk",
    component: _ede13eea,
    name: "aboutUs___at"
  }, {
    path: "/at/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___at"
  }, {
    path: "/at/szakivalaszol/uj-kerdes",
    component: _4a2811df,
    name: "addQuestionPage___at"
  }, {
    path: "/at/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___at"
  }, {
    path: "/at/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___at"
  }, {
    path: "/at/vezerlo/hirlevel-feliratkozasok",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___at"
  }, {
    path: "/at/vezerlo/inspiraciok",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___at"
  }, {
    path: "/at/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___at"
  }, {
    path: "/at/vezerlo/szakmak",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___at"
  }, {
    path: "/at/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___at"
  }, {
    path: "/cs/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___cs"
  }, {
    path: "/cs/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___cs"
  }, {
    path: "/cs/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___cs"
  }, {
    path: "/cs/conductor/inspirace",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___cs"
  }, {
    path: "/cs/conductor/newsletter-prihlasky",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___cs"
  }, {
    path: "/cs/conductor/profese",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___cs"
  }, {
    path: "/cs/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___cs"
  }, {
    path: "/cs/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___cs"
  }, {
    path: "/cs/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___cs"
  }, {
    path: "/cs/muj-ucet/ceny",
    component: _3c131fce,
    name: "addPricesPage___cs"
  }, {
    path: "/cs/muj-ucet/dostupne-prace",
    component: _d066ed6a,
    name: "buyTendersPage___cs"
  }, {
    path: "/cs/muj-ucet/moje-hodnoceni",
    component: _64fb9938,
    name: "RatingsPage___cs"
  }, {
    path: "/cs/muj-ucet/moje-nabidky",
    component: _62aad9cd,
    name: "myTenders___cs"
  }, {
    path: "/cs/muj-ucet/predplatne",
    component: _6e41ee2c,
    name: "subscriptionPage___cs"
  }, {
    path: "/cs/muj-ucet/probihajici-prace",
    component: _8d87a6a6,
    name: "boughtTendersPage___cs"
  }, {
    path: "/cs/muj-ucet/reference",
    component: _81489a52,
    name: "references___cs"
  }, {
    path: "/cs/static/o-nas",
    component: _ede13eea,
    name: "aboutUs___cs"
  }, {
    path: "/cs/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___cs"
  }, {
    path: "/cs/szakivalaszol/uj-kerdes",
    component: _4a2811df,
    name: "addQuestionPage___cs"
  }, {
    path: "/cs/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___cs"
  }, {
    path: "/cs/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___cs"
  }, {
    path: "/cs/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___cs"
  }, {
    path: "/cs/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___cs"
  }, {
    path: "/de/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___de"
  }, {
    path: "/de/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___de"
  }, {
    path: "/de/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___de"
  }, {
    path: "/de/fiokom/ajanlatkereseim",
    component: _62aad9cd,
    name: "myTenders___de"
  }, {
    path: "/de/fiokom/araim",
    component: _3c131fce,
    name: "addPricesPage___de"
  }, {
    path: "/de/fiokom/elofizetes",
    component: _6e41ee2c,
    name: "subscriptionPage___de"
  }, {
    path: "/de/fiokom/ertekeleseim",
    component: _64fb9938,
    name: "RatingsPage___de"
  }, {
    path: "/de/fiokom/folyamatban-levo-munkaim",
    component: _8d87a6a6,
    name: "boughtTendersPage___de"
  }, {
    path: "/de/fiokom/megszerezheto-munkaim",
    component: _d066ed6a,
    name: "buyTendersPage___de"
  }, {
    path: "/de/fiokom/referenciaim",
    component: _81489a52,
    name: "references___de"
  }, {
    path: "/de/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___de"
  }, {
    path: "/de/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___de"
  }, {
    path: "/de/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___de"
  }, {
    path: "/de/static/rolunk",
    component: _ede13eea,
    name: "aboutUs___de"
  }, {
    path: "/de/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___de"
  }, {
    path: "/de/szakivalaszol/uj-kerdes",
    component: _4a2811df,
    name: "addQuestionPage___de"
  }, {
    path: "/de/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___de"
  }, {
    path: "/de/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___de"
  }, {
    path: "/de/vezerlo/hirlevel-feliratkozasok",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___de"
  }, {
    path: "/de/vezerlo/inspiraciok",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___de"
  }, {
    path: "/de/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___de"
  }, {
    path: "/de/vezerlo/szakmak",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___de"
  }, {
    path: "/de/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___de"
  }, {
    path: "/en/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___en"
  }, {
    path: "/en/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___en"
  }, {
    path: "/en/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___en"
  }, {
    path: "/en/conductor/inspirations",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___en"
  }, {
    path: "/en/conductor/newsletter-subscriptions",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___en"
  }, {
    path: "/en/conductor/persons",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___en"
  }, {
    path: "/en/conductor/professions",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___en"
  }, {
    path: "/en/conductor/questions",
    component: _411086de,
    name: "conductorQuestionsIndexPage___en"
  }, {
    path: "/en/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___en"
  }, {
    path: "/en/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___en"
  }, {
    path: "/en/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___en"
  }, {
    path: "/en/my-account/jobs-in-progress",
    component: _8d87a6a6,
    name: "boughtTendersPage___en"
  }, {
    path: "/en/my-account/my-ratings",
    component: _64fb9938,
    name: "RatingsPage___en"
  }, {
    path: "/en/my-account/my-tenders",
    component: _62aad9cd,
    name: "myTenders___en"
  }, {
    path: "/en/my-account/obtainable-jobs",
    component: _d066ed6a,
    name: "buyTendersPage___en"
  }, {
    path: "/en/my-account/prices",
    component: _3c131fce,
    name: "addPricesPage___en"
  }, {
    path: "/en/my-account/references",
    component: _81489a52,
    name: "references___en"
  }, {
    path: "/en/my-account/subscription",
    component: _6e41ee2c,
    name: "subscriptionPage___en"
  }, {
    path: "/en/questions/new-question",
    component: _4a2811df,
    name: "addQuestionPage___en"
  }, {
    path: "/en/static/about-us",
    component: _ede13eea,
    name: "aboutUs___en"
  }, {
    path: "/en/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___en"
  }, {
    path: "/en/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___en"
  }, {
    path: "/en/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___en"
  }, {
    path: "/ro/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___ro"
  }, {
    path: "/ro/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___ro"
  }, {
    path: "/ro/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___ro"
  }, {
    path: "/ro/fiokom/ajanlatkereseim",
    component: _62aad9cd,
    name: "myTenders___ro"
  }, {
    path: "/ro/fiokom/araim",
    component: _3c131fce,
    name: "addPricesPage___ro"
  }, {
    path: "/ro/fiokom/elofizetes",
    component: _6e41ee2c,
    name: "subscriptionPage___ro"
  }, {
    path: "/ro/fiokom/ertekeleseim",
    component: _64fb9938,
    name: "RatingsPage___ro"
  }, {
    path: "/ro/fiokom/folyamatban-levo-munkaim",
    component: _8d87a6a6,
    name: "boughtTendersPage___ro"
  }, {
    path: "/ro/fiokom/megszerezheto-munkaim",
    component: _d066ed6a,
    name: "buyTendersPage___ro"
  }, {
    path: "/ro/fiokom/referenciaim",
    component: _81489a52,
    name: "references___ro"
  }, {
    path: "/ro/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___ro"
  }, {
    path: "/ro/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___ro"
  }, {
    path: "/ro/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___ro"
  }, {
    path: "/ro/static/rolunk",
    component: _ede13eea,
    name: "aboutUs___ro"
  }, {
    path: "/ro/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___ro"
  }, {
    path: "/ro/szakivalaszol/uj-kerdes",
    component: _4a2811df,
    name: "addQuestionPage___ro"
  }, {
    path: "/ro/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___ro"
  }, {
    path: "/ro/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___ro"
  }, {
    path: "/ro/vezerlo/hirlevel-feliratkozasok",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___ro"
  }, {
    path: "/ro/vezerlo/inspiraciok",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___ro"
  }, {
    path: "/ro/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___ro"
  }, {
    path: "/ro/vezerlo/szakmak",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___ro"
  }, {
    path: "/ro/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___ro"
  }, {
    path: "/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___hu"
  }, {
    path: "/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___hu"
  }, {
    path: "/vezerlo/ajanlatkeresek/biralas",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___hu"
  }, {
    path: "/vezerlo/blog/cikkek",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___hu"
  }, {
    path: "/vezerlo/blog/cimkek",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___hu"
  }, {
    path: "/vezerlo/blog/gyujtemenyek",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___hu"
  }, {
    path: "/vezerlo/inspiraciok/letrehozas",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___hu"
  }, {
    path: "/vezerlo/szakmak/letrehozas",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___hu"
  }, {
    path: "/at/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___at"
  }, {
    path: "/at/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___at"
  }, {
    path: "/at/vezerlo/ajanlatkeresek/biralas",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___at"
  }, {
    path: "/at/vezerlo/blog/cikkek",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___at"
  }, {
    path: "/at/vezerlo/blog/cimkek",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___at"
  }, {
    path: "/at/vezerlo/blog/gyujtemenyek",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___at"
  }, {
    path: "/at/vezerlo/inspiraciok/letrehozas",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___at"
  }, {
    path: "/at/vezerlo/szakmak/letrehozas",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___at"
  }, {
    path: "/cs/conductor/blog/clanky",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___cs"
  }, {
    path: "/cs/conductor/blog/kolekce",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___cs"
  }, {
    path: "/cs/conductor/blog/znacky",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___cs"
  }, {
    path: "/cs/conductor/blog/znacky-vytvorit",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___cs"
  }, {
    path: "/cs/conductor/inspirace/vytvorit",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___cs"
  }, {
    path: "/cs/conductor/profese/vytvorit",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___cs"
  }, {
    path: "/cs/conductor/zadosti-o-nabidku/posouzeni",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___cs"
  }, {
    path: "/cs/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___cs"
  }, {
    path: "/cs/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___cs"
  }, {
    path: "/de/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___de"
  }, {
    path: "/de/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___de"
  }, {
    path: "/de/vezerlo/ajanlatkeresek/biralas",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___de"
  }, {
    path: "/de/vezerlo/blog/cikkek",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___de"
  }, {
    path: "/de/vezerlo/blog/cimkek",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___de"
  }, {
    path: "/de/vezerlo/blog/gyujtemenyek",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___de"
  }, {
    path: "/de/vezerlo/inspiraciok/letrehozas",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___de"
  }, {
    path: "/de/vezerlo/szakmak/letrehozas",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___de"
  }, {
    path: "/en/conductor/blog/articles",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___en"
  }, {
    path: "/en/conductor/blog/collections",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___en"
  }, {
    path: "/en/conductor/blog/tags",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___en"
  }, {
    path: "/en/conductor/inspirations/create",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___en"
  }, {
    path: "/en/conductor/professions/create",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___en"
  }, {
    path: "/en/conductor/tenders/judge",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___en"
  }, {
    path: "/en/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___en"
  }, {
    path: "/en/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___en"
  }, {
    path: "/ro/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___ro"
  }, {
    path: "/ro/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___ro"
  }, {
    path: "/ro/vezerlo/ajanlatkeresek/biralas",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___ro"
  }, {
    path: "/ro/vezerlo/blog/cikkek",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___ro"
  }, {
    path: "/ro/vezerlo/blog/cimkek",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___ro"
  }, {
    path: "/ro/vezerlo/blog/gyujtemenyek",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___ro"
  }, {
    path: "/ro/vezerlo/inspiraciok/letrehozas",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___ro"
  }, {
    path: "/ro/vezerlo/szakmak/letrehozas",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___ro"
  }, {
    path: "/vezerlo/blog/cikkek/letrehozas",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___hu"
  }, {
    path: "/vezerlo/blog/cimkek/letrehozas",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___hu"
  }, {
    path: "/vezerlo/blog/gyujtemenyek/letrehozas",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___hu"
  }, {
    path: "/at/vezerlo/blog/cikkek/letrehozas",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___at"
  }, {
    path: "/at/vezerlo/blog/cimkek/letrehozas",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___at"
  }, {
    path: "/at/vezerlo/blog/gyujtemenyek/letrehozas",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___at"
  }, {
    path: "/cs/conductor/blog/clanky/vytvorit",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___cs"
  }, {
    path: "/cs/conductor/blog/kolekce/vytvorit",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___cs"
  }, {
    path: "/de/vezerlo/blog/cikkek/letrehozas",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___de"
  }, {
    path: "/de/vezerlo/blog/cimkek/letrehozas",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___de"
  }, {
    path: "/de/vezerlo/blog/gyujtemenyek/letrehozas",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___de"
  }, {
    path: "/en/conductor/blog/articles/create",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___en"
  }, {
    path: "/en/conductor/blog/collections/create",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___en"
  }, {
    path: "/en/conductor/blog/tags/create",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___en"
  }, {
    path: "/ro/vezerlo/blog/cikkek/letrehozas",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___ro"
  }, {
    path: "/ro/vezerlo/blog/cimkek/letrehozas",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___ro"
  }, {
    path: "/ro/vezerlo/blog/gyujtemenyek/letrehozas",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___ro"
  }, {
    path: "/at/vezerlo/blog/cikkek/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___at"
  }, {
    path: "/at/vezerlo/blog/cimkek/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___at"
  }, {
    path: "/at/vezerlo/blog/gyujtemenyek/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___at"
  }, {
    path: "/cs/conductor/blog/clanky/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___cs"
  }, {
    path: "/cs/conductor/blog/kolekce/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___cs"
  }, {
    path: "/cs/conductor/blog/znacky/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___cs"
  }, {
    path: "/de/vezerlo/blog/cikkek/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___de"
  }, {
    path: "/de/vezerlo/blog/cimkek/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___de"
  }, {
    path: "/de/vezerlo/blog/gyujtemenyek/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___de"
  }, {
    path: "/en/conductor/blog/articles/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___en"
  }, {
    path: "/en/conductor/blog/collections/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___en"
  }, {
    path: "/en/conductor/blog/tags/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___en"
  }, {
    path: "/ro/vezerlo/blog/cikkek/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___ro"
  }, {
    path: "/ro/vezerlo/blog/cimkek/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___ro"
  }, {
    path: "/ro/vezerlo/blog/gyujtemenyek/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___ro"
  }, {
    path: "/at/blog/cimke/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___at"
  }, {
    path: "/at/blog/gyujtemeny/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___at"
  }, {
    path: "/at/blog/szakma/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___at"
  }, {
    path: "/at/blog/tevekenyseg/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___at"
  }, {
    path: "/at/fiokom/beallitasaim/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___at"
  }, {
    path: "/at/fiokom/munkam/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___at"
  }, {
    path: "/at/inspiracio/szakma/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___at"
  }, {
    path: "/at/inspiracio/tevekenyseg/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___at"
  }, {
    path: "/at/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___at"
  }, {
    path: "/at/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___at"
  }, {
    path: "/at/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___at"
  }, {
    path: "/at/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___at"
  }, {
    path: "/at/vezerlo/inspiraciok/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___at"
  }, {
    path: "/at/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___at"
  }, {
    path: "/at/vezerlo/szakmak/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___at"
  }, {
    path: "/cs/blog/kolekce/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___cs"
  }, {
    path: "/cs/blog/profese/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___cs"
  }, {
    path: "/cs/blog/ukol/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___cs"
  }, {
    path: "/cs/blog/znacka/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___cs"
  }, {
    path: "/cs/conductor/inspirace/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___cs"
  }, {
    path: "/cs/conductor/profese/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___cs"
  }, {
    path: "/cs/inspirace/profese/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___cs"
  }, {
    path: "/cs/inspirace/ukol/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___cs"
  }, {
    path: "/cs/muj-ucet/moje-prace/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___cs"
  }, {
    path: "/cs/muj-ucet/nastaveni/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___cs"
  }, {
    path: "/cs/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___cs"
  }, {
    path: "/cs/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___cs"
  }, {
    path: "/cs/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___cs"
  }, {
    path: "/cs/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___cs"
  }, {
    path: "/cs/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___cs"
  }, {
    path: "/de/blog/cimke/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___de"
  }, {
    path: "/de/blog/gyujtemeny/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___de"
  }, {
    path: "/de/blog/szakma/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___de"
  }, {
    path: "/de/blog/tevekenyseg/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___de"
  }, {
    path: "/de/fiokom/beallitasaim/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___de"
  }, {
    path: "/de/fiokom/munkam/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___de"
  }, {
    path: "/de/inspiracio/szakma/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___de"
  }, {
    path: "/de/inspiracio/tevekenyseg/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___de"
  }, {
    path: "/de/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___de"
  }, {
    path: "/de/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___de"
  }, {
    path: "/de/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___de"
  }, {
    path: "/de/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___de"
  }, {
    path: "/de/vezerlo/inspiraciok/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___de"
  }, {
    path: "/de/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___de"
  }, {
    path: "/de/vezerlo/szakmak/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___de"
  }, {
    path: "/en/blog/collection/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___en"
  }, {
    path: "/en/blog/profession-task/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___en"
  }, {
    path: "/en/blog/profession/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___en"
  }, {
    path: "/en/blog/tag/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___en"
  }, {
    path: "/en/conductor/inspirations/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___en"
  }, {
    path: "/en/conductor/persons/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___en"
  }, {
    path: "/en/conductor/professions/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___en"
  }, {
    path: "/en/conductor/questions/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___en"
  }, {
    path: "/en/inspiration/profession-task/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___en"
  }, {
    path: "/en/inspiration/profession/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___en"
  }, {
    path: "/en/my-account/my-job/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___en"
  }, {
    path: "/en/my-account/settings/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___en"
  }, {
    path: "/en/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___en"
  }, {
    path: "/en/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___en"
  }, {
    path: "/en/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___en"
  }, {
    path: "/ro/blog/cimke/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___ro"
  }, {
    path: "/ro/blog/gyujtemeny/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___ro"
  }, {
    path: "/ro/blog/szakma/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___ro"
  }, {
    path: "/ro/blog/tevekenyseg/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___ro"
  }, {
    path: "/ro/fiokom/beallitasaim/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___ro"
  }, {
    path: "/ro/fiokom/munkam/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___ro"
  }, {
    path: "/ro/inspiracio/szakma/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___ro"
  }, {
    path: "/ro/inspiracio/tevekenyseg/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___ro"
  }, {
    path: "/ro/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___ro"
  }, {
    path: "/ro/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___ro"
  }, {
    path: "/ro/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___ro"
  }, {
    path: "/ro/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___ro"
  }, {
    path: "/ro/vezerlo/inspiraciok/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___ro"
  }, {
    path: "/ro/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___ro"
  }, {
    path: "/ro/vezerlo/szakmak/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___ro"
  }, {
    path: "/vezerlo/blog/cikkek/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___hu"
  }, {
    path: "/vezerlo/blog/cimkek/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___hu"
  }, {
    path: "/vezerlo/blog/gyujtemenyek/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___hu"
  }, {
    path: "/at/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___at"
  }, {
    path: "/cs/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___cs"
  }, {
    path: "/de/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___de"
  }, {
    path: "/en/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___en"
  }, {
    path: "/ro/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___ro"
  }, {
    path: "/at/ajanlatkeres/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___at"
  }, {
    path: "/at/arak/:professionTask",
    component: _8b1fad3c,
    name: "prices___at"
  }, {
    path: "/at/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___at"
  }, {
    path: "/at/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___at"
  }, {
    path: "/at/inspiracio/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___at"
  }, {
    path: "/at/megyek/:county",
    component: _00df2ea8,
    name: "county___at"
  }, {
    path: "/at/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___at"
  }, {
    path: "/at/projects/:id?",
    component: _58c66acc,
    name: "projects-id___at"
  }, {
    path: "/at/szakember/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___at"
  }, {
    path: "/at/szakemberek/:profession",
    component: _8f771e38,
    name: "professionPage___at"
  }, {
    path: "/at/szakivalaszol/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___at"
  }, {
    path: "/at/szakma/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___at"
  }, {
    path: "/at/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___at"
  }, {
    path: "/at/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___at"
  }, {
    path: "/blog/cimke/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___hu"
  }, {
    path: "/blog/gyujtemeny/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___hu"
  }, {
    path: "/blog/szakma/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___hu"
  }, {
    path: "/blog/tevekenyseg/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___hu"
  }, {
    path: "/cs/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___cs"
  }, {
    path: "/cs/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___cs"
  }, {
    path: "/cs/ceny/:professionTask",
    component: _8b1fad3c,
    name: "prices___cs"
  }, {
    path: "/cs/inspirace/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___cs"
  }, {
    path: "/cs/kraje/:county",
    component: _00df2ea8,
    name: "county___cs"
  }, {
    path: "/cs/odbornici/:profession",
    component: _8f771e38,
    name: "professionPage___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___cs"
  }, {
    path: "/cs/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___cs"
  }, {
    path: "/cs/profese/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___cs"
  }, {
    path: "/cs/projects/:id?",
    component: _58c66acc,
    name: "projects-id___cs"
  }, {
    path: "/cs/szakivalaszol/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___cs"
  }, {
    path: "/cs/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___cs"
  }, {
    path: "/cs/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___cs"
  }, {
    path: "/cs/zadost-o-nabidku/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___cs"
  }, {
    path: "/de/ajanlatkeres/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___de"
  }, {
    path: "/de/arak/:professionTask",
    component: _8b1fad3c,
    name: "prices___de"
  }, {
    path: "/de/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___de"
  }, {
    path: "/de/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___de"
  }, {
    path: "/de/inspiracio/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___de"
  }, {
    path: "/de/megyek/:county",
    component: _00df2ea8,
    name: "county___de"
  }, {
    path: "/de/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___de"
  }, {
    path: "/de/projects/:id?",
    component: _58c66acc,
    name: "projects-id___de"
  }, {
    path: "/de/szakember/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___de"
  }, {
    path: "/de/szakemberek/:profession",
    component: _8f771e38,
    name: "professionPage___de"
  }, {
    path: "/de/szakivalaszol/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___de"
  }, {
    path: "/de/szakma/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___de"
  }, {
    path: "/de/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___de"
  }, {
    path: "/de/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___de"
  }, {
    path: "/en/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___en"
  }, {
    path: "/en/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___en"
  }, {
    path: "/en/counties/:county",
    component: _00df2ea8,
    name: "county___en"
  }, {
    path: "/en/inspiration/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___en"
  }, {
    path: "/en/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___en"
  }, {
    path: "/en/prices/:professionTask",
    component: _8b1fad3c,
    name: "prices___en"
  }, {
    path: "/en/profession/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___en"
  }, {
    path: "/en/professional/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___en"
  }, {
    path: "/en/professionals/:profession",
    component: _8f771e38,
    name: "professionPage___en"
  }, {
    path: "/en/projects/:id?",
    component: _58c66acc,
    name: "projects-id___en"
  }, {
    path: "/en/questions/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___en"
  }, {
    path: "/en/request-for-tender/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___en"
  }, {
    path: "/en/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___en"
  }, {
    path: "/en/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___en"
  }, {
    path: "/fiokom/beallitasaim/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___hu"
  }, {
    path: "/fiokom/munkam/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___hu"
  }, {
    path: "/inspiracio/szakma/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___hu"
  }, {
    path: "/inspiracio/tevekenyseg/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___hu"
  }, {
    path: "/ro/ajanlatkeres/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___ro"
  }, {
    path: "/ro/arak/:professionTask",
    component: _8b1fad3c,
    name: "prices___ro"
  }, {
    path: "/ro/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___ro"
  }, {
    path: "/ro/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___ro"
  }, {
    path: "/ro/inspiracio/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___ro"
  }, {
    path: "/ro/megyek/:county",
    component: _00df2ea8,
    name: "county___ro"
  }, {
    path: "/ro/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___ro"
  }, {
    path: "/ro/projects/:id?",
    component: _58c66acc,
    name: "projects-id___ro"
  }, {
    path: "/ro/szakember/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___ro"
  }, {
    path: "/ro/szakemberek/:profession",
    component: _8f771e38,
    name: "professionPage___ro"
  }, {
    path: "/ro/szakivalaszol/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___ro"
  }, {
    path: "/ro/szakma/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___ro"
  }, {
    path: "/ro/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___ro"
  }, {
    path: "/ro/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___ro"
  }, {
    path: "/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___hu"
  }, {
    path: "/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___hu"
  }, {
    path: "/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___hu"
  }, {
    path: "/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___hu"
  }, {
    path: "/vezerlo/inspiraciok/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___hu"
  }, {
    path: "/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___hu"
  }, {
    path: "/vezerlo/szakmak/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___hu"
  }, {
    path: "/at/munka/:tenderId/pontositsunk",
    component: _502ba96f,
    name: "tender-tenderId-specify___at"
  }, {
    path: "/at/szakember/:professionalSeoName/ertekeles",
    component: _d01b40a8,
    name: "professionalRatingPage___at"
  }, {
    path: "/cs/odbornik/:professionalSeoName/hodnoceni",
    component: _d01b40a8,
    name: "professionalRatingPage___cs"
  }, {
    path: "/cs/prace/:tenderId/specifikace",
    component: _502ba96f,
    name: "tender-tenderId-specify___cs"
  }, {
    path: "/de/munka/:tenderId/pontositsunk",
    component: _502ba96f,
    name: "tender-tenderId-specify___de"
  }, {
    path: "/de/szakember/:professionalSeoName/ertekeles",
    component: _d01b40a8,
    name: "professionalRatingPage___de"
  }, {
    path: "/en/professional/:professionalSeoName/rating",
    component: _d01b40a8,
    name: "professionalRatingPage___en"
  }, {
    path: "/en/tender/:tenderId/specify",
    component: _502ba96f,
    name: "tender-tenderId-specify___en"
  }, {
    path: "/ro/munka/:tenderId/pontositsunk",
    component: _502ba96f,
    name: "tender-tenderId-specify___ro"
  }, {
    path: "/ro/szakember/:professionalSeoName/ertekeles",
    component: _d01b40a8,
    name: "professionalRatingPage___ro"
  }, {
    path: "/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___hu"
  }, {
    path: "/at/szakember/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___at"
  }, {
    path: "/at/szakember/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___at"
  }, {
    path: "/at/szakember/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___at"
  }, {
    path: "/at/szakember/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___at"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___cs"
  }, {
    path: "/de/szakember/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___de"
  }, {
    path: "/de/szakember/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___de"
  }, {
    path: "/de/szakember/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___de"
  }, {
    path: "/de/szakember/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___de"
  }, {
    path: "/en/professional/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___en"
  }, {
    path: "/en/professional/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___en"
  }, {
    path: "/en/professional/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___en"
  }, {
    path: "/en/professional/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___en"
  }, {
    path: "/ro/szakember/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___ro"
  }, {
    path: "/ro/szakember/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___ro"
  }, {
    path: "/ro/szakember/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___ro"
  }, {
    path: "/ro/szakember/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___ro"
  }, {
    path: "/at/munka/:tenderId/pontositsunk/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___at"
  }, {
    path: "/at/szakemberek/:profession/szakivalaszol/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___at"
  }, {
    path: "/cs/odbornici/:profession/odpovedi-odborniku/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___cs"
  }, {
    path: "/cs/prace/:tenderId/specifikace/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___cs"
  }, {
    path: "/de/munka/:tenderId/pontositsunk/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___de"
  }, {
    path: "/de/szakemberek/:profession/szakivalaszol/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___de"
  }, {
    path: "/en/professionals/:profession/questions/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___en"
  }, {
    path: "/en/tender/:tenderId/specify/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___en"
  }, {
    path: "/ro/munka/:tenderId/pontositsunk/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___ro"
  }, {
    path: "/ro/szakemberek/:profession/szakivalaszol/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___ro"
  }, {
    path: "/at/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___at"
  }, {
    path: "/at/arkalkulator/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___at"
  }, {
    path: "/at/inspiracio/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___at"
  }, {
    path: "/at/szakemberek/:profession/:city",
    component: _cdddc348,
    name: "professionals___at"
  }, {
    path: "/cs/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___cs"
  }, {
    path: "/cs/inspirace/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___cs"
  }, {
    path: "/cs/kalkulacka-cen/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___cs"
  }, {
    path: "/cs/odbornici/:profession/:city",
    component: _cdddc348,
    name: "professionals___cs"
  }, {
    path: "/de/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___de"
  }, {
    path: "/de/arkalkulator/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___de"
  }, {
    path: "/de/inspiracio/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___de"
  }, {
    path: "/de/szakemberek/:profession/:city",
    component: _cdddc348,
    name: "professionals___de"
  }, {
    path: "/en/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___en"
  }, {
    path: "/en/inspiration/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___en"
  }, {
    path: "/en/price-calculator/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___en"
  }, {
    path: "/en/professionals/:profession/:city",
    component: _cdddc348,
    name: "professionals___en"
  }, {
    path: "/ro/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___ro"
  }, {
    path: "/ro/arkalkulator/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___ro"
  }, {
    path: "/ro/inspiracio/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___ro"
  }, {
    path: "/ro/szakemberek/:profession/:city",
    component: _cdddc348,
    name: "professionals___ro"
  }, {
    path: "/at/megyek/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___at"
  }, {
    path: "/at/szakemberek/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___at"
  }, {
    path: "/cs/kraje/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___cs"
  }, {
    path: "/cs/odbornici/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___cs"
  }, {
    path: "/de/megyek/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___de"
  }, {
    path: "/de/szakemberek/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___de"
  }, {
    path: "/en/counties/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___en"
  }, {
    path: "/en/professionals/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___en"
  }, {
    path: "/ro/megyek/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___ro"
  }, {
    path: "/ro/szakemberek/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___ro"
  }, {
    path: "/ajanlatkeres/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___hu"
  }, {
    path: "/arak/:professionTask",
    component: _8b1fad3c,
    name: "prices___hu"
  }, {
    path: "/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___hu"
  }, {
    path: "/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___hu"
  }, {
    path: "/inspiracio/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___hu"
  }, {
    path: "/megyek/:county",
    component: _00df2ea8,
    name: "county___hu"
  }, {
    path: "/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___hu"
  }, {
    path: "/projects/:id?",
    component: _58c66acc,
    name: "projects-id___hu"
  }, {
    path: "/szakember/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___hu"
  }, {
    path: "/szakemberek/:profession",
    component: _8f771e38,
    name: "professionPage___hu"
  }, {
    path: "/szakivalaszol/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___hu"
  }, {
    path: "/szakma/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___hu"
  }, {
    path: "/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___hu"
  }, {
    path: "/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___hu"
  }, {
    path: "/munka/:tenderId/pontositsunk",
    component: _502ba96f,
    name: "tender-tenderId-specify___hu"
  }, {
    path: "/szakember/:professionalSeoName/ertekeles",
    component: _d01b40a8,
    name: "professionalRatingPage___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___hu"
  }, {
    path: "/munka/:tenderId/pontositsunk/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___hu"
  }, {
    path: "/szakemberek/:profession/szakivalaszol/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___hu"
  }, {
    path: "/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___hu"
  }, {
    path: "/arkalkulator/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___hu"
  }, {
    path: "/inspiracio/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___hu"
  }, {
    path: "/szakemberek/:profession/:city",
    component: _cdddc348,
    name: "professionals___hu"
  }, {
    path: "/megyek/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___hu"
  }, {
    path: "/szakemberek/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___hu"
  }, {
    path: "/at/*",
    component: _03cf66c8,
    name: "all___at"
  }, {
    path: "/de/*",
    component: _03cf66c8,
    name: "all___de"
  }, {
    path: "/cs/*",
    component: _03cf66c8,
    name: "all___cs"
  }, {
    path: "/ro/*",
    component: _03cf66c8,
    name: "all___ro"
  }, {
    path: "/en/*",
    component: _03cf66c8,
    name: "all___en"
  }, {
    path: "/",
    component: _1d4b0d3b,
    name: "indexPage___hu"
  }, {
    path: "/*",
    component: _03cf66c8,
    name: "all___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
